export default function (node, event, callback) {
  return {
    once () { this._add(this._onceListener()) },
    start () { this._add(callback) },
    stop () { this._remove(callback) },
    _add (listener) { node.addEventListener(event, listener, false) },
    _getOnceListener () {
      return (mouseEvent) => {
        callback(mouseEvent)
        this._remove(this._onceListener())
      }
    },
    _onceListener () {
      if (this.listener === undefined) this.listener = this._getOnceListener()
      return this.listener
    },
    _remove (listener) { node.removeEventListener(event, listener, false) }
  }
}
