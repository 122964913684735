import Device from 'shared/lib/device.js'

export default {
  _getPhotoItemsByIds (ids, width = 1024, height = 768) {
    let result = []
    for (let i = 0; i < ids.length; i++) {
      result.push({src: this._getPhotoURLById(ids[i], width, height), w: Device.pixelsWithRatio(width), h: Device.pixelsWithRatio(height)})
    }
    return result
  },

  _getPhotoURLById (id, width, height) {
    return `https://photo.hotellook.com/image_v2/crop/${id}/${Device.pixelsWithRatio(width)}/${Device.pixelsWithRatio(height)}.auto`
  }
}
