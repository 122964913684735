handler =
  lastErrorMessage: null
  decorate: (block, context) ->
    self = this
    # console.log block
    ->
      try
        block.apply(context || this, arguments)
      catch error
        error = error || 'Error is undefined'
        message = error.toString()
        unless self.lastErrorMessage == message
          self.lastErrorMessage = message
          output =
            msg: error.toString()
            error: error
          output.stack = error.stack if error.stack

        throw error

  handlePublic: (obj) ->
    item = obj.prototype if typeof obj == 'function'

    for name, value of item
      if name[0] != '_' && typeof value == 'function'
        item[name] = @decorate(value)
    obj

module.exports = handler
