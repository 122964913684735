import Pagination from 'shared/components/footer_pagination/pagination.js'

export default class extends Pagination {
  constructor (containerNode, options = {}) {
    options = {
      state: {button_text: __('search_results.show_more_hotels') },
      cssx: {
        scope: '.TPWL-widget .pagination_button',
        styles: {
          '.pagination_button-spinner .spinner div': {
            'background': window.TPWLCONFIG.color_scheme.text_contrast
          }
        }
      }
    }
    super(containerNode, options)
  }

  _initEvents (dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('serp_hotels_changed', (event, {hotels, filteredHotels}) => {
      this.itemsUpdated(filteredHotels)
      if (this.loading) { this.toggleLoader() }
    })

    dispatcher.on('pagination-show-more', (event, paginator) => {
      this.toggleLoader()
    })
  }

  toggleLoader () {
    this.loading = !this.loading
    this.view.createDocument().classList.toggle('pagination_button--loading', this.loading)
  }
}
