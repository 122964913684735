import Device from 'shared/lib/device.js'
import _merge from 'lodash/merge'
import dispatcher from 'shared/lib/dispatcher'
import _debounce from 'lodash/debounce'
const Circle = require('./circle_element.js')
const turf_helpers = require('@turf/helpers');
const turf_truncate = require('@turf/truncate');
const turf_distance = require('@turf/distance');

const primeColor = window.TPWLCONFIG.color_scheme.bg
const reversColor = window.TPWLCONFIG.color_scheme.btn_bg

const pointIcon = `<svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Drag</title><desc>Created using Figma</desc><use xlink:href="#a" transform="translate(2 2)" fill="${primeColor}"/><use xlink:href="#b" fill="#FFF"/><use xlink:href="#c" transform="translate(6 6)" fill="#FFF"/><defs><path id="a" d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"/><path id="b" fill-rule="evenodd" d="M26 13c0 7.18-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0s13 5.82 13 13zM13 24c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11z"/><path id="c" fill-rule="evenodd" d="M4.427 2.733L6.7.137c.158-.183.442-.183.6 0l2.273 2.596c.226.26.042.663-.3.663H7.9v2.702h2.704V4.726c0-.344.405-.527.663-.3l2.597 2.27c.182.16.182.444 0 .603l-2.597 2.27c-.258.227-.663.043-.663-.3V7.897H7.9v2.704h1.372c.343 0 .527.404.3.663l-2.27 2.596c-.16.183-.444.183-.603 0l-2.273-2.595c-.226-.258-.043-.663.3-.663H6.1V7.898H3.395v1.37c0 .345-.404.53-.663.303L.137 7.3c-.183-.16-.183-.444 0-.603l2.596-2.272c.26-.226.663-.043.663.3V6.1H6.1V3.395H4.727c-.344 0-.527-.404-.3-.662z"/></defs></svg>`
const radiusIcon = `<svg width="24" height="36" viewBox="0 0 24 36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Resize</title><desc>Created using Figma</desc><use xlink:href="#a" transform="translate(2 2)" fill="${reversColor}"/><use xlink:href="#b" fill="#FFF"/><use xlink:href="#c" transform="translate(9 12)" fill="#FFF"/><defs><path id="a" d="M0 3c0-1.657 1.343-3 3-3h14c1.657 0 3 1.343 3 3v26c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3z"/><path id="b" fill-rule="evenodd" d="M5 0h14c2.76 0 5 2.24 5 5v26c0 2.76-2.24 5-5 5H5c-2.76 0-5-2.24-5-5V5c0-2.76 2.24-5 5-5zm0 2C3.343 2 2 3.343 2 5v26c0 1.657 1.343 3 3 3h14c1.657 0 3-1.343 3-3V5c0-1.657-1.343-3-3-3H5z"/><path id="c" fill-rule="evenodd" d="M0 1c0-.552.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1zm0 5c0-.552.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1zm1 4c-.552 0-1 .448-1 1s.448 1 1 1h4c.552 0 1-.448 1-1s-.448-1-1-1H1z"/></defs></svg>`

const createSVGImage = (width, height, svg) => {
  const image = document.createElement('img');
  image.width = width;
  image.height = height;
  image.src = `data:image/svg+xml;base64,${window.btoa(svg)}`;
  return image;
}
const pointImage = createSVGImage(26, 26, pointIcon);
const radiusImage = createSVGImage(24, 36, radiusIcon);

class MapCircle {
  static defaultRadius() {
    return 10000
  }
  static maxRadius() {
    return 49000
  }
  static metersToKilometers(distance) {
    return Math.round((distance / 1000) * 10) / 10
  }
  static defaultOptions() {
    return {
      strokeColor: primeColor,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: primeColor,
      draggable: false,
      editable: false,
      fillOpacity: 0.05
    }
  }

  constructor(map, center = map.getCenter(), radius = MapCircle.defaultRadius(), options = {}) {
    this.map = map
    this.options = _merge(options, MapCircle.defaultOptions())
    this.options.center = this.center = center;
    this.options.radius = this.radius = radius;
    this.shown = true
    this._initCircle(this.options)
    this._updateFilterDebounced = _debounce(this._updateFilter.bind(this), 200);
  }

  _animateCircle() {
    let geojson = this.shown ? this.circle.asGeojson() : {
      type: "FeatureCollection",
      "features": []
    };
    this.map.map.getSource('circle-1').setData(geojson);
  }

  _initCircle(options) {
    const map = this.map.map
    const isMetric = LOCALE.toUpperCase() !== 'EN_US'
    var units =  isMetric ? 'kilometers' : 'miles'

    this.circle = new Circle(
      [this.center.lng, this.center.lat],
      this.radius / 1000,
      {
        units: units,
        zoom: map.getZoom(),
        properties: {}
      }
    );
    let moveEvent, startEvent, endEvent, leaveEvent, enterEvent;
    const leaveCallback = () => {
      this.map._leaveCallback();
      map.dragPan.enable();
    };

    [moveEvent, startEvent, endEvent, leaveEvent, enterEvent] = Device.isTouch() ?
      ['touchmove', 'touchstart', 'touchend', 'touchend', 'touchstart'] :
      ['mousemove', 'mousedown', 'mouseup', 'mouseleave', 'mouseenter'];

    var adjustCirclePrecision = () => {
      this.circle.updateZoom(map.getZoom());
      this._animateCircle();
    }

    var onMoveCircle = (e) => {
      let mousePoint = turf_truncate(turf_helpers.point(map.unproject(e.point).toArray()), 6);
      this.update(undefined, mousePoint.geometry.coordinates)
    }

    var mouseUpCircle = () => {
      map.off(moveEvent, onMoveCircle);
    }

    var mouseDownCircle = (e) => {
      map.on(moveEvent, onMoveCircle);
      map.once(endEvent, mouseUpCircle);
      if (Device.isTouch()) map.once(leaveEvent, leaveCallback);
    };

    var onMovePoint = (e) => {
      let clickPoint = map.unproject(e.point).toArray();
      this.update(turf_distance(this.circle.getCenter(), clickPoint, units) * 1000)
    }

    var mouseUpPoint = () => {
      map.off(moveEvent, onMovePoint);
    }

    var mouseDownPoint = (e) => {
      map.on(moveEvent, onMovePoint);
      map.once(endEvent, mouseUpPoint);
      if (Device.isTouch()) map.once(leaveEvent, leaveCallback);
    };

    map.on(leaveEvent, 'circle-center-point', leaveCallback);
    map.on(leaveEvent, 'circle-control-points', leaveCallback);
    map.on(startEvent, 'circle-center-point', mouseDownCircle);
    map.on(startEvent, 'circle-control-points', mouseDownPoint);
    map.on(enterEvent, 'circle-center-point', () => {
      map.dragPan.disable();
      this.map._enterCallback('move')();
    });
    map.on(enterEvent, 'circle-control-points', () => {
      this.map._enterCallback('ew-resize')();
      map.dragPan.disable();
    });

    map.addImage('point', pointImage);
    map.addImage('radius', radiusImage);

    map.addSource('circle-1', {
      type: "geojson",
      data: this.circle.asGeojson(),
      tolerance: 0.01,
      maxzoom: 11
    });

    map.addLayer({
      id: "circle-line",
      type: "line",
      source: "circle-1",
      paint: {
        "line-color": window.TPWLCONFIG.color_scheme.bg,
        "line-width": 2
      },
      filter: ["==", "$type", "Polygon"]
    })

    map.addLayer({
      id: "circle-fill",
      type: "fill",
      source: "circle-1",
      paint: {
        "fill-color": window.TPWLCONFIG.color_scheme.bg,
        "fill-opacity": 0.2
      },
      filter: ["==", "$type", "Polygon"]
    });

    map.addLayer({
      id: "circle-control-points",
      type: "symbol",
      source: "circle-1",
      layout: {
        "icon-image": "radius"
      },
      filter: ["all", ["==", "$type", "Point"],
        ["!=", "type", "center"]
      ]
    });

    map.addLayer({
      id: "circle-center-point",
      type: "symbol",
      source: "circle-1",
      layout: {
        "icon-image": "point"
      },
      filter: ["all", ["==", "$type", "Point"],
        ["==", "type", "center"]
      ]
    });

    map.on('zoomend', adjustCirclePrecision);
  }

  update(radius, center, forced = false) {
    let centerChanged = this.setCenter(center);
    let radiusChanged = this.setRadius(radius);

    if (forced || centerChanged || radiusChanged) {
      this._updateFilterDebounced();
    }
  }

  _updateFilter() {
    dispatcher.send('map_circle_changed', {
      point: {
        lon: this.center.lng,
        lat: this.center.lat,
      },
      radius: MapCircle.metersToKilometers(this.radius)
    });
  }

  getCenter() {
    return {
      lat: this.circle.getCenter().lat(),
      lng: this.circle.getCenter().lng()
    }
  }

  setCenter(center) {
    if (center && !center.lat) center = {
      lat: center[1],
      lng: center[0]
    }

    if (!center || (this.center.lat === center.lat && this.center.lng === center.lng)) return false
    this.center = center

    this.circle.updateCenter([this.center.lng, this.center.lat]);
    this._animateCircle();
    return true
  }

  getRadius() {
    return this.circle.getRadius()
  }

  setRadius(radius) {
    if (!radius || this.radius === radius) return false
    if (radius > MapCircle.maxRadius()) {
      return this.setRadius(MapCircle.maxRadius())
    }
    this.radius = radius
    this.circle.updateRadius(this.radius / 1000);
    this._animateCircle();
    return true
  }

  deleteFromMap() {
    this.shown = false;
    this._animateCircle();
  }
}

var circle = false;
export default function (map, center, radius, options) {
  if (circle) {
    let forced = !circle.shown
    if (forced) circle.shown = true;
    circle.update(radius, center, forced)
  } else {
    circle = new MapCircle(map, center, radius, options);
  }

  return circle
}
