{% import Card from '../card/card.js' %}
{% import Spinner from 'shared/components/spinner/spinner.monk' %}
{% import Fade from 'shared/components/fade/fade.monk' %}

<div class="layout_manager">
  <Fade/>
  <div class="search_results-mobile_sticky--invisible_wrapper">
    <div class="search_results-mobile_sticky" role="mobile_sticky">
      <div class="search_results-mobile_sticky__destination">{{ destination }}</div>
      <div class="search_results-mobile_sticky__params">{{ params.booking_period_text }},&nbsp;{{ params.guests }}</div>
      <div class="search_results-mobile_sticky__edit" role="params_edit_trigger"></div>
    </div>
  </div>
  <div class="search_progressbar-container" data-title="{{ __('search_progressbar.hotel_title') }}" is="search_progressbar"></div>
  <div class="expired-search-plate hidden" is="expired_search"></div>
  <div class="system-messages hidden" is="system_messages"></div>
  <div class="search_results-wrapper" role="search_results-wrapper">
    <div class="show_filters_wrapper" role="show_filters" is="show_filters_button"></div>
    <div class="search_results-filters__swipe-trigger" role="swipe-trigger"></div>
    <div class="filters-container" role="filters_wrapper" is="filters_wrapper">
      <div is="map" class="search_results-map"></div>
      <div class="search_results-wrapper-filters search_results-wrapper-filters--left" role="swipe-target">
        <div class="filters_layout">
          <div class="filters-header">
            {{ __('filters.header') }}
            <span role="close_filters"></span>
            <div class="filters-counter">
            <div class="counter">{{__('filters.hotels')}}: <span>{{ filteredHotels }}/{{ totalHotels }}</span></div>
            <div class="relax_filters {{ filteredHotels == totalHotels ? 'invisible' : '' }}" role="reset_filters">
            {% unsafe __('filters.clear_all') %}
            </div>
            </div>
          </div>
          <main>
            <div is="sorting" class="search_results-sorting"></div>
            <div is="filters" class="search_results-filters"></div>
          </main>
        </div>
      </div>
    </div>

    <div class="search_results-wrapper-tickets search_results-wrapper-tickets--right">
      {% if googletagIsAvailable %}
        <!-- /68258039/729x250_Top_HL -->
        <div id="div-gpt-ad-1603114093821-0" class="google-ad" style="width: 714px; height: 210px; margin-bottom: 20px; display: none">
          <script>
            googletag.cmd.push(function() { googletag.display("div-gpt-ad-1603114093821-0"); });
          </script>
        </div>
      {% endif %}
      <div class="search-results">
        <div class="search-results-title">
          <div class="search-results-title--wrapper" role="search_result_title">
            {% if destination && hotels.length %}
              <span class="search-results-title__city">
                {{destination}}:&nbsp;<span class="rtl_inline">&rlm;&nbsp;</span></span>
              {% if totalHotels %}
              <span class="search-results-title__total">
                {{totalHotels}}&nbsp;{{ filteredHotels | pluralize(__('card.search_title.objects')) }}.&nbsp;<span class="rtl_inline">&rlm;&nbsp;</span></span>
              <span class="search-results-title__shown">
                {{ __('card.search_title.show') }}<span class="rtl_inline-hide">&nbsp;</span><span class="search-results-title__shown_number">1&ndash;{{shownHotels}}</span>.<span class="rtl_inline">&rlm;</span></span>
              {% else %}
                {{ __('card.search_title.searching') }}
              {% endif %}
            {% endif %}
          </div>
        </div>
        <div class="search-results-cards">
          <ul class="search-results-cards-wrapper">
            {% for hotel of firstHotelsPart %}
              <li class="search-results-cards-wrapper-card">
                  <Card
                    hotel="{{ hotel }}"
                    currency="{{ currency }}"
                    destination="{{ destination }}"
                  />
              </li>
            {% endfor %}
            {% if googletagIsAvailable && firstHotelsPart.length %}
                <!-- /68258039/729x250_Ticket_HL -->
                <div id="div-gpt-ad-1603113974696-0" class="google-ad" style="width: 714px; height: 210px; margin-bottom: 20px; display: none">
                  <script>
                    googletag.cmd.push(function() { googletag.display("div-gpt-ad-1603113974696-0"); });
                  </script>
                </div>
            {% endif %}
            {% for hotel of lastHotelsPart %}
              <li class="search-results-cards-wrapper-card">
                  <Card
                    hotel="{{ hotel }}"
                    currency="{{ currency }}"
                    destination="{{ destination }}"
                  />
              </li>
            {% endfor %}
          </ul>
          <div class="hidden" is="footer_pagination"></div>
            <div class="cards-filtering_loader">
              <div class="cards-filtering_loader-panel">
                <Spinner />
                <div class="cards-filtering_loader-panel__title">
                  {{ __('card.filtering.title') }}
                </div>
                <div class="cards-filtering_loader-panel__description">
                  {{ __('card.filtering.description') }}
                </div>
              </div>
            </div>
          <div class="search-results-cards__loader"></div>
        </div>
      </div>
    </div>
    {% if googletagIsAvailable %}
    <!-- /68258039/160x600-HL -->
    <div id="div-gpt-ad-1584004628365-0" class="google-ad" style="width: 160px; height: 600px; margin-top: 20px; margin-left: 20px; display: none">
      <script>
        googletag.cmd.push(function() { googletag.display("div-gpt-ad-1584004628365-0"); });
      </script>
    </div>
    {% endif %}
    {% if yaAdfoxIsAvailable %}
    <!--yandex_aviasales-ads-->
    <!--Площадка: TravelPayouts / Тестовый баннер / Hotels / Небоскрёб-->
    <!--Тип баннера: 160х600 Небоскрёб на вайт-лейбле-->
    <div class="yandex-ad" style="width: 160px; height: 600px; margin-top: 20px; margin-left: 20px"></div>
    {% endif %}
  </div>
  <div class="search_results-photoswipe" is="photoswipe"></div>
  <div class="search_results-scroll_top_button" is="scroll_top_button"></div>
</div>
