module.exports = require('./error_handler.coffee').handlePublic {
  preloaderQueue: [[], []]
  preloadedUrls: []
  processedUrls: []
  preloading: 0
  callbacks: {}
  clearQueue: -> preloaderQueue = [[], []]
  preload: (url, callback, priority) ->
    return callback() if @preloadedUrls.indexOf(url) != -1
    @callbacks[url] ||= []
    return @callbacks[url].push(callback) if(@callbacks[url].length > 0)

    if([0, 1].indexOf(priority) == -1)
      priority = if @preloading >= 6 then 1 else 0

    if @preloading >= 6
      @preloaderQueue[priority].push({url: url, callback: callback})
    else
      @callbacks[url].push(callback)
      @preloading++
      image = new Image()
      image.onload = =>
        @preloadedUrls.push(url)
        @preloading--
        cb() for cb in @callbacks[url]
        @callbacks[url] = []
        for i in [0,1]
          if next = @preloaderQueue[i].shift()
            @preload(next.url, next.callback, i)
            break
      image.src = url
    @processedUrls.push(url)
}
