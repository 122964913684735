import Template from './advantages.monk'

export default class Advantages extends Template {
  constructor () {
    super()

    this.on('click', '[role="advantages_expander"]', (event, target) => {
      target.parentNode.querySelector('.hotel_page-advantages-list').classList.toggle('hotel_page-advantages-list--expanded')
      target.classList.toggle('hotel_page-advantages-info_button--expanded')
    })
  }
}
