import _defaultsDeep from 'lodash/defaultsDeep'
import RangeFilter from 'shared/components/filters/range/range.js'

export default class RatingsFilter extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'ratings_filter',
      state: {
        title: __('filters.ratings')
      }
    }, super.defaultOptions())
  }

  getShortState () {
    return [this.options.filterState.min]
  }
}
