import DOMComponent from 'shared/components/base';
import Device from 'shared/lib/device.js';
import _dispatcher from 'shared/lib/dispatcher';
import filters from 'shared/lib/filters.coffee';
import PopupScroller from 'shared/lib/popup_scroller';

import Template from './proposals.monk';

export default class HotelProposals extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'hotel_proposals',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.hotel_page-proposals-group-header-title a': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-proposals-group-expander': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.hotel_page-proposals-group-best_proposal__expand_link': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-proposals .hotel_page-proposals-container.hotel_page-proposals--expanded': {
            'background-color': window.TPWLCONFIG.color_scheme.body_bg
          },
          '.hotel_page-proposals-container__loader .spinner div': {
            'background-color': window.TPWLCONFIG.color_scheme.bg
          }
        }
      },
      render: {
        template: Template,
        replace: true,
        options: {filters}
      },
      state: {
        direction: ['ar', 'he'].indexOf(LOCALE) !== -1,
        searchFinished: false,
        bestGroup: {items: [], proposal: false},
        hotel: {
          id: false,
          grouped_proposals: [],
          best_group: {},
          proposal: false
        },
        proposals: [],
        targetLink: '_blank'
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.discounts = {}

    const isMobileSafari = this.detectMobileSafari()
    
    if (isMobileSafari) {
      this.state.targetLink = '_self';
    }
  }

  _initDOMEvents (view) {
    view.on('click', '[role="proposals_expander"]', (event, target) => {
      if (this.proposalsNode === undefined) this.proposalsNode = view.querySelector('[role="proposals_list"]')
      Device.lockScroll()
      this.proposalsNode.classList.add('hotel_page-proposals--expanded')
      _dispatcher.send('modal_opened', {id: 'hotels_proposal'});
      PopupScroller.toggle()
    })

    view.on('click', '[role="proposals_group_expander"]', (event, target) => {
      // Proposals toggle animation logic
      const classList = target.parentNode.classList
      const getClass = name => `hotel_page-proposals-group--animation-${name}`
      classList.toggle('hotel_page-proposals-group--expanded')

      if (classList.contains(getClass('fold')) || classList.contains(getClass('unfold'))) {
        classList.toggle(getClass('fold'))
        classList.toggle(getClass('unfold'))
      } else {
        classList.add(getClass('unfold'))
      }
    })

    view.on('click', '[role="hotel_page-mobile-back"]', (event, target) => {
      Device.unlockScroll()
      this.proposalsNode.classList.remove('hotel_page-proposals--expanded')
      PopupScroller.toggle()
      _dispatcher.send('modal_closed', {id: 'hotels_proposal'});
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('hotel_page_hotels_changed', (event, {hotel}) => {
      this.state.hotel = hotel
      this.refresh()
    })

    dispatcher.on('params_restored', (event, params) => {
      this.state.currency = params.params.currency
      this.state.hotel.location_name = params.params.destination
    })
  }

  detectMobileSafari() {
    const isMobileSafari = /Version\/([0-9\._]+).*Mobile.*Safari.*/.test(navigator.userAgent)

    return isMobileSafari
  }
}
