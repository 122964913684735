{% import Gallery from 'hotels/components/gallery/gallery.js' %}
{% import Spinner from 'shared/components/spinner/spinner.monk' %}
{% import GateInfo from './gate_info.monk' %}

<div class="card {{ !hotel.proposal ? 'card--busy' : '' }} {{ hotel.isSelected ? 'card--selected' : '' }}" data-id="{{ hotel.id }}">
  <div class="card-gallery">
    <Gallery photosIds="{{ hotel.photos_ids }}" width="{{false}}" height="{{false}}"/>
  </div>

  <section class="card-info">
    <a href="{{ hotel.link }}" class="card-hotel_link"  role="hotel_link" target="_blank"
          metric="SERP_HOTEL_LINK">
      <div class="card-announcement">
        <div class="card-type">
          {{ hotel.type }}
        </div>

        <div class="card-rating_stars card-rating_stars--{{ hotel.stars }}"></div>

        {% if(hotel.rating > 0) %}
          <div class="card-rating_number card-rating_number--green">
            {{ hotel.rating | withDecimalDigit }}
          </div>
        {% endif %}
      </div>

      <div class="card-hotel_name" title="{{ hotel.name }}">
        <span class="card-hotel_name__text">
          {{ hotel.name }}
        </span>
      </div>

      {% if(hotel.district) %}
        <div class="card-location" role="card-location-map_preview_trigger">
          {{ hotel.district }}
        </div>
      {% endif %}
    </a>

    {% if(hotel.proposals && hotel.proposals[1]) %}
      <ul class="card-gates_list">
        {% for proposal of hotel.proposals.slice(1, 5) %}
          <li class="card-gates_list-item">
            <a href="{{ proposal.gate_deeplink }}source=serp&sourceDetails=serpProposals" target="_blank" class="card-gates_list-item-deeplink" metric="CARD_PROPOSALS_CLICK">
              <span class="card-gates_list__name">{{ proposal.gate_name }}</span>
              <span class="card-gates_list__price"><span class="currency_font currency_font--{{ currency }}">{{ proposal.price | split_price }}</span></span>
            </a>
          </li>
        {% endfor %}

        <li class="card-gates_list-item">
          <a href="{{ hotel.link }}" class="card-more_proposals"  role="hotel_link" target="_blank" metric="SERP_HOTEL_LINK">
            {{ __('card.more_proposals') }}
          </a>
        </li>
      </ul>
    {% else %}
      <div class="card-gates_list">
        {% if !hotel.proposal %}
          <div class="card-fake_gates_list-item"></div>
          <div class="card-fake_gates_list-item"></div>
          <div class="card-fake_gates_list-item"></div>
        {% endif %}
        <div class="card-gates_list-item">
          <a href="{{ hotel.link }}" class="card-more_proposals"  role="hotel_link" target="_blank" metric="SERP_HOTEL_LINK">
            {{ __('card.more_proposals') }}
          </a>
        </div>
      </div>
    {% endif %}
  </section>

  <div class="card-main_gate">
    {% if(hotel.proposal) %}
      <a href="{{ hotel.proposal.gate_deeplink }}source=serp&sourceDetails=serpButton" target="_blank" class="card-main_gate__link" metric="CARD_MAIN_PROPOSAL_CLICK">
        <GateInfo proposal="{{ hotel.proposal }}" button_text="{{ __('card.pick_room') }}" currency="{{currency}}" button_role="pick_room_button"/>
      </a>
      <span class="card-main_gate__link card-main_gate__link--expired">
        <GateInfo proposal="{{ hotel.proposal }}" button_text="{{ __('informer.update_results') }}" currency="{{currency}}" button_role="reload_button"/>
      </span>
    {% else %}
      <div class="card-busy_hotel">
        <span class="card-busy_hotel__text">
          {{ __('card.no_rooms_available') }}
        </span>
        <div class="main_gate-button main_gate-button--busy" role="main_gate-button--busy">
          {{ __('card.choose_different_dates') }}
        </div>
      </div>

      <div class="card-loader">
        <div>
          {{ __('card.loader_text') }}
        </div>
      </div>
    {% endif %}
  </div>
</div>
