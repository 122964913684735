import _map from "lodash/map";
import _isArray from "lodash/isArray";
import _merge from "lodash/merge";
import Params from "shared/lib/search_params.js";
import autocompleteRequest from "hotels/lib/autocomplete_request.js";
import cookieParams from "hotels/lib/cookie_params.coffee";
import dispatcher from "shared/lib/dispatcher";
import getDefaultParams from "./strategies/default_params.coffee";
import getHotellookParams from "./strategies/hotellook_params.coffee";
import legacyParams from "./strategies/legacy_params.js";
import marker from "shared/lib/marker.coffee";
import cookies from "shared/lib/cookies.coffee";
import HCMetrics from "../../lib/hotelscombined_metrics";

class HotelParams extends Params {
  onRestored(callback) {
    dispatcher.on("params_restored", callback, { execute_last: true });
  }

  _getParams() {
    if (legacyParams.checkForRedirect()) return false;

    let result = _merge({}, getDefaultParams());

    this.parsedParams = getHotellookParams(window.location.search);

    let fromCookieParams = cookieParams.get_params();
    if (
      !(
        this.parsedParams["locations_ids"] ||
        this.parsedParams["destination"] ||
        this.parsedParams["rooms"] ||
        this.parsedParams["check_in"] ||
        this.parsedParams["check_out"]
      )
    ) {
      _merge(result, fromCookieParams);
    } else if (fromCookieParams.currency) {
      result.currency = fromCookieParams.currency;
    }

    if (this.parsedParams.flags && this.parsedParams.flags.utmDetail) {
      cookies.set(
        "utmDetail",
        this.parsedParams.flags.utmDetail,
        new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      );
    } else if (cookies.get("utmDetail")) {
      if (!this.parsedParams.flags) this.parsedParams.flags = {};
      this.parsedParams.flags.utmDetail = cookies.get("utmDetail");
    }

    return _merge(result, this.parsedParams);
  }

  _castParams(params) {
    let keys = ["hotels_ids", "locations_ids"];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (!_isArray(params[key]))
        params[key] = params[key] ? [params[key]] : [];
      params[key] = _map(params[key], parseInt);
    }

    this.hasHotelId = !!params.hotels_ids.length;
    this.hasLocationId = !!params.locations_ids.length;
    params.marker = marker.get();
    return params;
  }

  _restoreParamsCallback() {
    cookieParams.set_params(this.params);
    dispatcher.send("params_restored", { params: this.params }, "parser");
    if (this.params["geo"]) {
      dispatcher.send(
        "locations_updated",
        {
          locations: {
            [undefined]: {
              center_coords: this.params["geo"],
              name: this.params["geo"]["name"],
              full_name: this.params["geo"]["fullName"]
            }
          }
        },
        "parser"
      );
    }
    this._setPage();
  }

  _checkParamsAndSendTriggers() {
    super._checkParamsAndSendTriggers();
    if (
      document.readyState === "complete" ||
      document.readyState === "loaded" ||
      document.readyState === "interactive"
    ) {
      if (!this.isRestored) {
        document.body.classList.remove("TPWL-widget--no_params");
        this.isRestored =
          !!this.params.locations_ids.length ||
          !!this.params["geo"] ||
          !!this.params.hotels_ids.length;
        if (this.isRestored) {
            this._restoreParamsCallback();
        } else {
          document.body.classList.add("TPWL-widget--no_params");
          this._setPage("serp");
        }
      }
    } else {
      setTimeout(() => {
        this._checkParamsAndSendTriggers();
      }, 1000);
    }
  }

  _updateIds(ids) {
    let changed = false;
    if (!this.hasHotelId && ids.hotel) {
      changed = this.hasHotelId = true;
      this.params.hotels_ids.push(ids.hotel);
    }
    if (!this.haslocationId && ids.location) {
      changed = this.hasLocationId = true;
      this.params.locations_ids.push(ids.location);
    }

    if (changed) this._checkParamsAndSendTriggers();
  }

  _requestAdditionalData() {
    super._requestAdditionalData();
    const destination = this.params.destination;

    if (
      !this.hasHotelId &&
      !this.hasLocationId &&
      destination &&
      !this.params["geo"]
    ) {
      autocompleteRequest(destination, body => {
        if (body.cities[0]) {
          HCMetrics.logDestination(destination, body.cities[0], body);
          this._updateIds({ location: body.cities[0].id });
        } else if (body.hotels[0]) {
          HCMetrics.logDestination(destination, body.hotels[0], body);
          this._updateIds({
            location: body.hotels[0].locationId,
            hotel: body.hotels[0].id
          });
        } else {
          HCMetrics.logDestination(destination, null, body);
          throw new Error(
            "Can't find hotel with id = " + this.params.hotels_ids[0]
          );
        }
        this._checkParamsAndSendTriggers();
      });
    }
  }

  _getPage() {
    return this.hasHotelId
      ? "hotel_page"
      : this.hasLocationId
      ? "serp"
      : this.isRestored
      ? super._getPage()
      : false;
  }
}

let params = new HotelParams();
module.exports = params;
