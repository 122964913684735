import HotelsExpiredSearch from 'hotels/components/expired_search/expired_search.js';
import Map from 'hotels/components/map/map.js';
import Mewtwo from 'hotels/components/mewtwo/mewtwo.js';
import Params from 'hotels/components/params_parser/search_params.js';
import Hotel from 'hotels/lib/models/hotel';
import Proposal from 'hotels/lib/models/proposal';
import _defaultsDeep from 'lodash/defaultsDeep';
import _each from 'lodash/each';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import DOMComponent from 'shared/components/base';
import colorUtils from 'shared/lib/color_utils.js';
import dictionary from 'shared/lib/dictionary.js';
import _dispatcher from 'shared/lib/dispatcher';
import filters from 'shared/lib/filters.coffee';
import metrics from 'shared/lib/metrics.coffee';
import photoUtils from 'shared/lib/photo_utils.js';
import router from 'shared/lib/router.js';

import Template from './hotel_page.monk';

const buttonColor = `%23${window.TPWLCONFIG.color_scheme.btn_bg.slice(1)}`
const buttonTextColor = `%23${window.TPWLCONFIG.color_scheme.btn_text.slice(1)}`
const linkColor = `%23${window.TPWLCONFIG.color_scheme.link.slice(1)}`

export default class HotelPage extends DOMComponent {
  static defaultOptions() {
    return {
      name: 'hotel_page',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.hotel_page-search_details': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.hotel_proposal-price .hotel_proposal-price__button': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '.hotel_proposal-special': {
            'border-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_bg
          },
          '.hotel_proposal-price .hotel_proposal-price__button:hover': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff')
          },
          '.hotel_proposal-price .hotel_proposal-price__button:hover ~ .hotel_proposal-special': {
            'border-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff'),
            'color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff')
          },
          '.TPWL_widget--xs .hotel_proposal-price .hotel_proposal-price_text .currency_font': {
            'color': window.TPWLCONFIG.color_scheme.btn_bg
          },
          '.TPWL_widget--xs .hotel_proposal-price .hotel_proposal-price_text .hotel_proposal-price-discount__old_price .currency_font': {
            'color': '#FA6161'
          },
          'a.hotel_page-serp_link__link': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-address': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          'a.hotel_page-main_gate-button-link': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg
          },
          'a.hotel_page-main_gate-button-link:hover': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff')
          },
          'a.hotel_page-proposals-group-best_proposal__buy_button': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '.hotel_page-proposals-header': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.map-photo-wrapper__poi': {
            'background-image':
              `url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ccircle%20cy%3D%226%22%20cx%3D%226%22%20r%3D%226%22%20stroke%3D%22%${buttonTextColor}%22%20fill%3D%22${buttonColor}%22%20%2F%3E%3C%2Fsvg%3E)`
          },
          'a.hotel_page-similar_hotels-price_button': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '.hotel_page-similar_hotels-price_button:hover': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff')
          },
          '.hotel_page-footer_search_link': {
            'background-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.hotel_page-available_rooms-header__link': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-available_rooms-header__link:after': {
            'background-image': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22${linkColor}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M3.104%2010.085l-1.697-1.69%207.89-7.89C9.593.177%2010.04%200%2010.486%200c.447%200%20.863.178%201.19.504l.507.504-9.08%209.077zM2.687%2011.3l1.905%201.958-3.9.742h-.12c-.148%200-.296-.06-.416-.178-.12-.148-.178-.356-.148-.534l.774-3.885L2.687%2011.3zm10.33-9.46l.477.503c.327.297.506.742.506%201.187%200%20.445-.18.86-.506%201.186l-7.89%207.86-1.696-1.69%209.11-9.047z%22%2F%3E%3C%2Fsvg%3E")`
          },
          '.hotel_page-available_rooms-header__link:before': {
            'background-image': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22${linkColor}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M3.104%2010.085l-1.697-1.69%207.89-7.89C9.593.177%2010.04%200%2010.486%200c.447%200%20.863.178%201.19.504l.507.504-9.08%209.077zM2.687%2011.3l1.905%201.958-3.9.742h-.12c-.148%200-.296-.06-.416-.178-.12-.148-.178-.356-.148-.534l.774-3.885L2.687%2011.3zm10.33-9.46l.477.503c.327.297.506.742.506%201.187%200%20.445-.18.86-.506%201.186l-7.89%207.86-1.696-1.69%209.11-9.047z%22%2F%3E%3C%2Fsvg%3E")`
          },
          '.hotel_page-advantages-info_button span': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          'TPWL_widget--xs .hotel_page-proposals-group-expander span': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page .hotel_page-link-with-arrow:after': {
            'background-image': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2217%22%20viewBox%3D%220%200%2010%2017%22%3E%3Cpath%20fill%3D%22${linkColor}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M1.485%2016.97l-.707-.707%208.485-8.486.707.708-8.485%208.485zm7.07-8.485L.78.705%201.485%200l7.778%207.777-.707.708z%22%2F%3E%3C%2Fsvg%3E")`
          },
          '.hotel_page-mobile-location-map__photo:after': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'border-color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '#tpwl-side-form:before': {
            'border-bottom-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.hotel_page-rating_pie__text': {
            'background': window.TPWLCONFIG.color_scheme.body_bg
          },
          '.hotel_page-serp_link__link:before': {
            'background-image':
              `url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2010%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EArrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M7.966%2016L9.5%2014.455S2.965%208%202.976%207.976L9.43%201.498%207.94%200%200%207.986%207.966%2016z%22%20fill%3D%22${window.TPWLCONFIG.color_scheme.link}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E")`
          }
        }
      },
      render: { template: Template, options: { filters } },
      state: {
        is_loading: true,
        location_link: false,
        hotel: {
          photos_ids: [],
          reviews: [],
          location_decl: 'Some city',
          name: 'Some hotel name',
          rating: false,
          location_name: 'Some city',
          property_type: 'hotel',
          address: 'Some hotel address',
          searchParams: {},
          api_key: Map.API_KEY,
          main_images: {
            review: 'Some hotel guests review review review review review review review review'
          },
          proposals: [],
          amenitiesv2: {}
        },
        reviewIncrease: 5,
        reviewRequest: false,
        currency: 'rub',
        bestGroup: false,
        searchFinished: false
      }
    }
  }

  constructor(node, options = {}) {
    super(node, options)
    this.refresh()
    this.location = false
    this.discounts = {}
  }

  _initEvents(dispatcher) {
    metrics.reach_goal('PAGE_VIEW')

    dispatcher.on('locations_updated', (event, { locations }) => {
      let locationId = Params.params.locations_ids[0] || this.state.hotel.location_id
      if (this.state.hotel.location_name && this.state.hotel.location_name.search(',') == -1 && locations[locationId]) { this.state.hotel.location_name = `${this.state.hotel.location_name}, ${locations[locationId].country_name}` }
      if (!this.location && locations[locationId]) {
        this.state.location = this.location = locations[locationId]

        this.state.location_link = this.generateLocationLink(Params.params)
        this.refresh()
      }
    })

    dispatcher.on('currency_updated', (event, currency) => {
      this.state.currency = currency
      this.refresh()
    })
    dispatcher.on('start_search', () => { this.state.searchFinished = false; this.refresh() })
    dispatcher.on('search_finished', () => { this.state.searchFinished = true; this.refresh() })

    dispatcher.on('hotel_page_hotels_changed', (event, { request_id: searchId, hotel, hotels_suggests, hotels_by_id: hotelsById }) => {
      if (!this.form) {
        let formContainerNode = this.view.querySelector('[role="available_rooms-form"]')
        let option = {}
        if (hotel !== undefined) { option.state = { hotel_name: hotel.name } }
        if (formContainerNode) {
          this.form = new Mewtwo(formContainerNode, option)
        }
      }

      if (hotel !== undefined) {
        this.state.is_loading = false
        this.state.hotel = _defaultsDeep(hotel, this.state.hotel)
        let data = {
          request_id: searchId,
          hotels_map: {
            [this.state.hotel.id]: {
              pin: [this.state.hotel['location']['lat'], this.state.hotel['location']['lon']],
              info: this.state.hotel,
              actual: true
            }
          }
        }
        dispatcher.send('map_hotels_updated', data)

        if (!this.location && dictionary.locations[this.state.hotel.location_id]) {
          this.state.location = this.location = dictionary.locations[this.state.hotel.location_id]
          this.state.location_link = this.generateLocationLink(Params.params)
        }
      }

      dispatcher.on('close_gallery', () => {
        dispatcher.send('modal_closed', { id: 'gallery' }) // Hack to close gallery as a popup in iframe
      });

      this.state.similarHotels = this.initSimilarHotels(hotels_suggests, hotelsById)

      this.refresh()
    })

    dispatcher.on('params_restored', (event, params) => {
      this.state.hotel.location_name = params.params.destination
    })

    // hack for best group
    dispatcher.on('best_group_changed', (event, bestGroup) => {
      this.state.bestGroup = bestGroup
      this.refresh()
    })
  }

  initSimilarHotels(suggests, hotelsById) {
    let result = []
    _each(suggests, (suggest) => {
      let hotel = new Hotel(hotelsById[suggest.id])
      hotel.proposalsUpdated([new Proposal({ price: suggest.price }, hotel)])
      result.push(hotel)
    })

    return _sortBy(result, 'proposal.price')
  }

  _initDOMEvents(view) {
    view.on('click', '[role="map-page-trigger"]', (event, target) => {
      view.querySelector('[role="find_hotel-toggler"]').classList.add('map-find_button-wrapper--hidden')
      router.page = 'map'
    })

    view.on('click', '[role="available_rooms-form_trigger"]', (event, target) => {
      if (this.availableFormContainerNode === undefined) {
        this.availableFormContainerNode = view.querySelector('[role="available_rooms-form-container"]')
      }
      this.availableFormContainerNode.classList.toggle('hotel_page-available_rooms-form_container--expanded')
    })

    view.on('click', '[role="gallery_toggler"]', (event, target) => {
      metrics.reach_goal('PAGE_GALLERY_FULLSCREEN')
      let dataset = target.dataset
      let ids = []
      if (dataset.roomId !== undefined) {
        ids = this.state.hotel.photos_by_room_type[parseInt(dataset.roomId, 10)].photos_ids
      } else if (dataset.hotelId !== undefined && parseInt(dataset.hotelId, 10) === this.state.hotel.id) {
        ids = this.state.hotel.photos_ids
      }
      let width = window.innerWidth < 1024 ? window.innerWidth : 1024
      width = width < 245 ? 245 : width
      if (ids.length) {
        _dispatcher.send('show_gallery', { items: photoUtils._getPhotoItemsByIds(ids, width, Math.round(width / 4 * 3)), options: target })
      }
    })

    view.on('click', '[role="reload_button"]', HotelsExpiredSearch.reloadCallback)

    view.on('click', '[role="link_to_book_now"]', (event, target) => {
      const arrClassList = ['hotel_page-proposals-group-best_proposal__buy_button',
        'hotel_page-proposals-group-best_proposal__expand_link',
        'hotel_page-proposals-group-best_proposal__buy_button']
      if (arrClassList.some(element => event.target.classList.contains(element))) {
        return
      }
      target.parentNode.querySelector('.hotel_proposal-price-button_wrapper').click()
    })

    view.on('click', '.hotel_proposal-price-button_wrapper', () => {
      _dispatcher.send('buy_hotel_clicked', { requestParams: { params: Params.params }, id: this.state.hotel.id })
    })
  }

  generateLocationLink(searchParams) {
    let children = _reduce(searchParams.rooms, (sum, obj) => {
      return obj.children ? sum + obj.children.join(',') : undefined
    }, '')
    let adults = _reduce(searchParams.rooms, (sum, obj) => { return sum + parseInt(obj.adults, 10) }, 0)
    let link = `/hotels?destination=${this.location.name}&locationId=${this.location.id}&checkIn=${searchParams.check_in}&checkOut=${searchParams.check_out}&children=${children}&adults=${adults}&language=${searchParams.locale}&with_request=true`
    return link
  }
}
