<section class="hotel_page-header">
  <header class="hotel_page-serp_link">
    <a href="{{ location_link }}" class="hotel_page-serp_link__link">{{ __('hotel_header.to_search_results') }}</a>

    <a href="/" class="hotel_page-serp_link__link hotel_page-serp_link__link--crumbs">{{ __('hotel_header.to_main_page') }}</a>

    <a href="{{ location_link }}" class="hotel_page-serp_link__link hotel_page-serp_link__link--crumbs">{{ location.name }}</a>

    <span class="hotel_page-serp_link__link hotel_page-serp_link__link--crumbs">{{ hotel.name }}</span>
  </header>

  <main class="hotel_page-header-details">
    <div class="hotel_page-info">
      <div class="hotel_page-hotel_name">
        {{ hotel.name }}
      </div>

      <div class="hotel_page-rating_type">
        <div class="hotel_page__type">{{ hotel.type }}</div>
        <div class="hotel_page-rating_stars hotel_page-rating_stars--{{ hotel.stars }}"></div>
      </div>

      <address class="hotel_page-address" role="map-page-trigger">
        {{ hotel.address }}
      </address>
    </div>

    {% if(hotel.rating) %}
      <div class="hotel_page-reviews_details">
        <div class="hotel_page-reviews_details-description">
          <div class="hotel_page-reviews_details-description__label">
            {{ __('hotel_page.reviews_mark') }}
          </div>
          {% if (hotel.reviews_count) %}
            <div class="hotel_page-reviews_details-description__count">
              {{ __('hotel_page.rating.based_on') }}&nbsp;{{ hotel.reviews_count | pluralize(__('hotel_page.reviews')) }}
            </div>
          {% endif %}
        </div>
        <div class="hotel_page-reviews_details-rating">
          <span>{{ __('hotel_header.rating') }}</span>
          {{ hotel.rating | withDecimalDigit }}
        </div>
      </div>
    {% endif %}
  </main>
</section>
