import _forEach from 'lodash/forEach'
import Hotel from './models/hotel'
import Proposal from './models/proposal'
import dispatcher from 'shared/lib/dispatcher'

let hotels = {
  _index: {},
  hotels: [],
  hotel_proposals: {},
  selected_hotels_ids: [],
  discounts: {},
  hotel: false,
  request_id: false,
  filteredHotels: 0,
  totalHotels: 0,

  init () {
    dispatcher.on('serp_hotels_updated', (event, params) => {
      this._processEvent(event, params)
    })
    dispatcher.on('hotel_page_hotels_updated', (event, params) => {
      this._processEvent(event, params)
    })
  },

  _processEvent (event, {request_id: searchId, selected_hotels_ids, hotels, hotel_proposals: proposals, hotels_suggests: hotelsSuggests, hotels_by_id: hotelsById, discounts, filteredHotels, totalHotels}) {
    this.request_id = searchId
    if (filteredHotels !== undefined) this.filteredHotels = filteredHotels
    if (selected_hotels_ids !== undefined) this.selected_hotels_ids = selected_hotels_ids
    if (totalHotels !== undefined) this.totalHotels = totalHotels
    if (hotelsById !== undefined) this.hotels_by_id = hotelsById
    if (hotelsSuggests !== undefined) this.hotels_suggests = hotelsSuggests
    if (hotels !== undefined) this._processHotels(hotels)
    if (proposals !== undefined) this._processProposals(proposals)
    if (discounts !== undefined) this._processDiscounts(discounts)
    dispatcher.send(event.replace('updated', 'changed'), this)
  },

  _processHotels (hotels) {
    this.hotels = []

    hotels.forEach((hotel) => {
      let id = parseInt(hotel.id, 10)
      let item = this._index[id]
      if (item === undefined) item = this._initializeHotel(hotel, id)
      item.isSelected = this.selected_hotels_ids.indexOf(id) >= 0
      this.hotels.push(item)
    })

    this.hotel = this.hotels[0]
  },

  _initializeHotel (hotel, id) {
    let instance = new Hotel(hotel, this.request_id)
    this._index[id] = instance
    return instance
  },

  _processProposals (proposals) {
    this.hotel_proposals = {}
    this._proposalsIndex = {}

    _forEach(proposals, (proposalsObj, hotelId) => {
      let result = []
      hotelId = parseInt(hotelId, 10)
      let hotel = this._index[hotelId]

      proposalsObj.proposals.forEach((proposal) => {
        let id = this._getProposalId(hotelId, proposal)
        let instance = this._index[id]

        if (instance === undefined) {
          instance = new Proposal(proposal, hotel)
          this._index[id] = instance
        }
        result.push(instance)
      })

      hotel && hotel.proposalsUpdated(result)

      this.hotel_proposals[hotelId] = {proposals: result}
    })
  },

  _getProposalId (hotelId, proposal) {
    return [hotelId, proposal.gate_id, proposal.room_id].join(':')
  },

  _processDiscounts (discounts) {
    this.discounts = discounts
    _forEach(discounts, (byGates, hotelId) => {
      _forEach(byGates.gates_rooms, (byRooms, gateId) => {
        _forEach(byRooms.discounts, (discount, roomId) => {
          let proposalId = this._getProposalId(hotelId, {gate_id: gateId, room_id: roomId})
          let proposal = this._index[proposalId]
          if (proposal !== undefined) proposal.discountChanged(discount)
        })
      })
    })
  }
}

hotels.init()

export default hotels
