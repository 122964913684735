import _map from 'lodash/map'
import _defaultsDeep from 'lodash/defaultsDeep'
import RangeFilter from 'shared/components/filters/range/range.js'
import Params from 'hotels/components/params_parser/search_params.js'
import filters from 'shared/lib/filters.coffee'

const currencyMultipliers = {'usd': 0.018, 'azn': 0.03, 'chf': 0.018, 'cny': 0.1, 'egp': 0.3, 'eur': 0.016, 'idr': 200, 'nok': 0.15, 'kzt': 5.5, 'ltl': 0.06, 'php': 1, 'pkr': 2, 'thb': 0.6, 'uah': 0.5, 'zar': 0.2, 'byr': 300, 'jpy': 2, 'myr': 0.08, 'brl': 0.05, 'ars': 0.3, 'cop': 50.826, 'pen': 0.06, 'clp': 10, 'aed': 0.07, 'sar': 0.07, 'sek': 0.15, 'pln': 0.07, 'try': 0.06, 'huf': 5, 'kgs': 1, 'cad': 0.025, 'mxn': 0.3, 'amd': 8, 'xof': 10, 'vnd': 400, 'bgn': 0.03, 'gel': 0.04, 'ron': 0.07, 'dkk': 0.1, 'bdt': 1.4, 'krw': 20, 'rsd': 2, 'aud': 0.024, 'gbp': 0.014, 'hkd': 0.13, 'nzd': 0.025, 'sgd': 0.025}

export default class PriceFilter extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'price_filter',
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              if (!this.currency) this.currency = Params.params.currency.toLowerCase()
              return `<span class="currency_font currency_font--${this.currency}">${filters.split_price(value)}</span>`
            }
          }
        }
      },
      state: {
        title: __('filters.price_title'),
        getAnyText () { return __('filters.any_price') }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    let currency = Params.params.currency.toLowerCase()
    if (currency in currencyMultipliers) {
      let multiplier = currencyMultipliers[currency]
      options.options.steps = _map(options.options.steps, (value) => {
        if (value === 0 || value === Infinity) return value
        value = value * multiplier
        Math.log10 = Math.log10 || function (x) {
          return Math.log(x) * Math.LOG10E
        }
        let order = Math.pow(10, (Math.floor(Math.log10(value)) - 1))

        return Math.round(Math.round(value / order) * order / 5) * 5
      })
    }
    super(node, options)
  }
}
