{% import dictionary from 'shared/lib/dictionary.js' %}
{% import Gallery from 'hotels/components/gallery/gallery.js' %}

<section class="hotel_page-photos hotel_page-photos--{{hotel.photos_count}}_photos" role="page_photos">
  <div class="main_photo-wrapper">
    <div class="main_photo">
      <Gallery photosIds="{{ hotel.photos_ids }}" width="{{false}}" height="{{false}}" />
    </div>
    {% if hotel.best_group && hotel.best_group.proposal %}
      <div class="main_photo-price_block">
        <div class="main_photo-price_block-button">
          <a class="main_photo-price_block-button-link main_photo-price_block-button-link--{{ hotel.best_group.proposal.discount ? 'discount' : 'no_discount' }}" href="{{ hotel.best_group.proposal.gate_deeplink }}source=hotel&sourceDetails=hotelButton" metric="HOTEL_PAGE_MAIN_PROPOSAL_CLICK" target="_blank">
              <div class="main_photo-price_block-button-link-discount-wrapper">
                <div class="main_photo-price_block-button-link-discount">
                  {% if hotel.best_group.proposal.discount %}
                    <span class="main_photo-price_block-button-link-discount__text"> {{ __('hotel_page.discount') }} </span>
                    <span class="main_photo-price_block-button-link-discount__percent">{{ hotel.best_group.proposal.discount.change_percentage | abs }}&rlm;%</span>
                  {% endif %}
                </div>
              </div>
            <div class="main_photo-price_block-button-link__price">
              {{ __('hotel_page.similar_hotels.from') }}&nbsp;
              <span class="currency_font currency_font--{{ currency }}">{{ hotel.best_group.proposal.price | split_price }}</span>
            </div>
            <span class="main_photo-price_block-button-link__from">{{ __('hotel_page.on') }}&nbsp;{{ hotel.best_group.proposal.gate_name }}</span>
          </a>
          <span class="main_photo-price_block-button-link  main_photo-price_block-button-link--expired" role="reload_button">
            {{ __('informer.update_results') }}
          </span>
        </div>
      </div>
    {% endif %}
    <span class="main_photo__zoom" role="gallery_toggler" data-hotel-id="{{hotel.id}}"></span>
  </div>
  <div class="galery_map_photo-wrapper">
    <div class="galery_photo-wrapper">
      <div class="galery_photo" style="{{ hotel.main_images.galery_url ? 'background-image: url(' + hotel.main_images.galery_url + ');' : ''}}">
      </div>
      <div class="galery_photo-background" role="gallery_toggler" data-hotel-id="{{hotel.id}}">
        <div class="galery_photo-background-wrapper">
          <div class="galery_photo-background-wrapper_looking_all">
            {{ __('hotel_page.look_all') }}
          </div>
        </div>
      </div>
    </div>
    <div class="map-photo-wrapper" role="map-page-trigger">
      <div class="map-photo" style="{{ hotel.main_images.map_url ? 'background-image: url(' + hotel.main_images.map_url + ');' : ''}}"></div>
      <span class="map-photo-wrapper__poi"></span>
      <span class="map-photo-wrapper__text">{{ __('hotel_page.look_map') }}</span>
    </div>
  </div>
</section>
