import _extend from 'lodash/extend'
import Template from './rating.monk'
import dictionary from 'shared/lib/dictionary.js'

export default class Rating extends Template {
  constructor () {
    super()

    this.state = {hotel: {}}

    this.on('click', '[role="ratings_expander"]', (event, target) => {
      target.parentNode.classList.toggle('hotel_page-rating_bars--expanded')
    })
  }

  update (state) {
    _extend(this.state, state)
    if (!this.state.hotel.ratings && this.state.hotel.id) {
      this.state.hotel.ratings = dictionary.getRow('trustyou', this.state.hotel.id)
      this.state.hotel.ratings === undefined ||
      this.state.hotel.ratings.sentiment_score_list
      .forEach(el => (el.score = el.score / 10))
    }

    super.update(this.state)
  }
}
