import metrics from "shared/lib/metrics.coffee";

const HCMetrics = {
  enabled: false,
  init() {
    this.enabled = window.location.search.indexOf("hc_script=1") !== -1;
    console.log(this.enabled);

    if (!this.enabled) {
      this.logDestination = function() {};
      return false;
    }
  },
  logDestination(sourceDestination, targetDestionation, rawData) {
    metrics.send_metric({
      goal: "HC_HL_destination",
      sourceDestination,
      hotelFullName: targetDestionation.hotelFullName,
      latinLocationFullName: targetDestionation.latinLocationFullName,
      autocompleteURL:
        "https://yasen.hotellook.com/autocomplete?term=" +
        encodeURIComponent(sourceDestination),
      locationSearch: window.location.send_metric
    });
  }
};

HCMetrics.init();

export default HCMetrics;
