_extend = require('lodash/extend')
cookies = require('shared/lib/cookies.coffee')
utils = require('shared/lib/utils.coffee')
moment = require('moment')

module.exports =
  set_params: (params) ->
    return unless params.destination

    search_params = {
      params_attributes:
        check_in: params.check_in
        check_out: params.check_out
        adults: params.adults
        children: params.children
        destination:
          name: params.destination
          location_id: params.locations_ids[0]
    }

    cookies.set('hotel_search_params', JSON.stringify(search_params), 'unlimited')

  get_params: ->
    return {} if (window.location.search.length < 10 && !window.location.search.match(/with_request/))

    search_params = _extend({params_attributes: {}}, JSON.parse(cookies.get('hotel_search_params')))

    params = {
      destination: search_params.params_attributes.destination && search_params.params_attributes.destination.name
      locations_ids: (search_params.params_attributes.destination && [search_params.params_attributes.destination.location_id])
      rooms:[
          adults: +search_params.params_attributes.adults || 2
          children: search_params.params_attributes.children || []
      ]
    }
    if search_params.params_attributes.check_in
      params.check_in = moment(search_params.params_attributes.check_in, 'YYYY-MM-DD')
      if moment().add(-2, 'days') > params.check_in
        params.check_in = moment().add(1, 'days')

    if search_params.params_attributes.check_out
      params.check_out = moment(search_params.params_attributes.check_out, 'YYYY-MM-DD')
      if params.check_in > params.check_out
        params.check_out = params.check_in.clone().add(1, 'days')

    params.check_in = params.check_in.format('YYYY-MM-DD') if params.check_in
    params.check_out = params.check_out.format('YYYY-MM-DD') if params.check_out

    currency = cookies.get('currency')
    params.currency = currency.toLowerCase() if(currency && /^\w{3}$/.test(currency))

    return params
