import xhr from 'xhr'

export default function (term, callback, type = false) {
  xhr({
    uri: 'https://yasen.hotellook.com/autocomplete?term=' + encodeURIComponent(term)},
    (err, resp, body) => {
      if (!resp || resp.statusCode !== 200) {
        console.error('Error getting location_id by destination', err, resp)
      }

      try {
        body = JSON.parse(body)
      } catch (e) {
        console.error('Error location_id parsing')
      }

      callback(body)
    }
  )
}
