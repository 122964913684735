<div class="hotel_page-advantages-info">
  <h4 class="hotel_page-advantages-info_title">{{ title }}</h4>
  <ul class="hotel_page-advantages-list hotel_page-advantages-list--expanded">
    {% for item of list %}
      <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--{{ item.slug }}">{{ item.name }}</li>
    {% endfor %}
  </ul>
  <button role="{{ size > 7 && 'advantages_expander' }}"
          class="hotel_page-advantages-info_button  hotel_page-link-with-arrow hotel_page-advantages-info_button--expanded">
    <span role="unfold">{{ __('hotel_page.advantages.unfold') }}</span>
    <span role="fold">{{ __('hotel_page.advantages.fold') }}</span>
  </button>
</div>
