import _defaultsDeep from 'lodash/defaultsDeep'
import RangeFilter from 'shared/components/filters/range/range.js'
import Template from 'shared/components/filters/range/range.monk'

const mileToKmRatio = 1.60934

export default class DistanceToFilter extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'distance_to',
      render: {
        template: Template,
        options: {
          filters: {
            decorateMeasurement (value) {
              const isMetric = LOCALE.toUpperCase() !== 'EN_US'
              const distance = isMetric ?  value : value * mileToKmRatio;
              return Math.round(distance * 10) / 10 + __('filters.km')
            }
          }
        }
      },
      state: {
        title: __('filters.distance'),
        getAnyText () { return __('filters.any_distance') }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    const {point, max, mode} = options.options
    options.defaultFilterState.point = point
    options.defaultFilterState.radius = max
    options.defaultFilterState.mode = mode
    options.filterState.point = point
    options.filterState.mode = mode
  }

  parseShortState (state) {
    let [distance, lat, lon, mode] = state
    const point = lat && lon ? {lat: Number(lat), lon: Number(lon)} : {}
    const max = distance && this._nearestGroup(Number(distance))

    if (['point', 'location_center'].indexOf(mode) === -1) {
      mode = point.lat === undefined ? 'location_center' : 'point'
    }

    return { point, mode, max }
  }

  getShortState () {
    const {max, point, mode} = this.options.filterState
    return [max, point.lat, point.lon, mode]
  }

  getState () {
    const {point, max: radius, mode} = this.options.filterState
    return {point, radius, mode}
  }

  resetState () {
    this.state.title = __('filters.distance')
    super.resetState()
  }

  updateState (data, forced = false) {
    super.updateState(data, forced)
    this.state.title = this.options.filterState.mode === 'point' ? __('filters.distance_from_point') : __('filters.distance')
  }
}
