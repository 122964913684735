import Template from './card.monk'
import _dispatcher from 'shared/lib/dispatcher'
import SwipeDetector from 'shared/lib/swipe_detector.coffee'
import HotelsExpiredSearch from 'hotels/components/expired_search/expired_search.js'
import Device from 'shared/lib/device'
import Params from 'hotels/components/params_parser/search_params.js'

export default class Card extends Template {
  constructor () {
    super()
    this.mapPterviewShown = false
    this.linkSelector = this.querySelector('[role="hotel_link"]')
    window.requestAnimationFrame(() => {
      let galleryNode = this.querySelector('[role="gallery_wrapper"]')
      new SwipeDetector(this, {
        callbacks: {
          left: () => {
            _dispatcher.send('card_swipe', {position: 'left', node: galleryNode})
          },
          right: () => {
            _dispatcher.send('card_swipe', {position: 'right', node: galleryNode})
          }
        },
        selector: '[role="gallery_wrapper"]'
      })
      galleryNode.addEventListener('click', this.linkHandler.bind(this))
    })

    this.on('click', '[role="reload_button"]', HotelsExpiredSearch.reloadCallback)
    this.on('mouseover', '[role="card-location-map_preview_trigger"]', (event, node) => {
      let previewNode = this.createDocument().querySelector('[role="card-location-map_preview"]')
      if (previewNode && previewNode.dataset && previewNode.dataset.mapUrl !== '') {
        let img = `url('${previewNode.dataset.mapUrl}')`
        if (previewNode.style.backgroundImage !== img) {
          previewNode.style.backgroundImage = img
        }
      }
    })

    this.on('click', '[role="main_gate-button--busy"]', () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    })

    this.on('click', '.card-main_gate__link', () => {
      _dispatcher.send('buy_hotel_clicked', { requestParams: { params: Params.params }, id: this.createDocument().dataset.id })
    })

    this.on('click', '.card-gates_list-item-deeplink', () => {
      _dispatcher.send('buy_hotel_clicked', { requestParams: { params: Params.params }, id: this.createDocument().dataset.id })
    })
  }

  linkHandler (event) {
    if (Device.isTouch() && event.target.classList.contains('hotels-gallery_list-item')) {
      event.preventDefault()
      const url = `${window.location.origin}${this.linkSelector.getAttribute('href')}`
      window.history.pushState(null, null, url)
      window.location.reload()
    }
    this.linkSelector.classList.add('.card-hotel_link--active')
  }
}
