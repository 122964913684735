{% import Hotel from './hotel.monk' %}

<div class="map">
  <div class="map-container" role="map-container">
    <div id="tpwl-map" class="map-viewport"></div>
    {% if activeHotel %}
      <div class="map-active-hotel-container"
      style="{{ activeHotel.tooltip | positionStyle('left') }}{{ activeHotel.tooltip | positionStyle('right') }}{{ activeHotel.tooltip | positionStyle('top') }}">
        <Hotel hotel="{{ activeHotel }}" fullView="{{ false }}" currency="{{currency}}"/>
      </div>
    {% endif %}

    {% if forcedHotel %}
      <div class="map-forced-hotel-container">
        <Hotel hotel="{{ forcedHotel }}" fullView="{{ true }}" currency="{{currency}}"/>
      </div>
    {% endif %}
  </div>
  <div class="map-button_container" role="map-toggler">
      <div class="map__image" />
      <span class="map__button">{{ __('hotels_on_map') }}</span>
      <span class="map__close_button">{{ __('hotels_on_map_close') }}</span>
  </div>
  <div class="map-find_button-wrapper" role="find_hotel-toggler">
    <span class="map-find_button"><span>{{  forcedCircle ? __('hotels_off_find_hotels') : __('hotels_on_find_hotels') }}</span></span>
  </div>
</div>
