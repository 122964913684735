import _merge from 'lodash/merge'
import ComponentsInitializer from 'shared/main.js'
import Params from 'hotels/components/params_parser/search_params.js' // DO NOT DELETE THIS
import hotels from 'hotels/lib/hotels.js'  // DO NOT DELETE THIS

class HotelsComponentsInitializer extends ComponentsInitializer {
  componentsMap () {
    performance.mark && performance.mark("hotels_application-start")
    let hotelComponents = {
      'mewtwo': require('hotels/components/mewtwo/mewtwo.js').default,
      'search_results': require('hotels/components/search_results/search_results.js').default,
      'footer_pagination': require('hotels/components/footer_pagination/pagination.js').default,
      'filters': require('hotels/components/filters/filters.js').default,
      'filters_wrapper': require('hotels/components/filters/filters_wrapper.js').default,
      'sorting': require('hotels/components/sorting/sorting.js').default,
      'map': require('hotels/components/map/map.js').default,
      'hotel_page': require('hotels/components/hotel_page/hotel_page.js').default,
      'photoswipe': require('hotels/components/photoswipe/photoswipe.js').default,
      'scroll_top_button': require('shared/components/scroll_top_button/scroll_top_button.js').default,
      'expired_search': require('hotels/components/expired_search/expired_search.js').default,
      'system_messages': require('hotels/components/system_messages/system_messages.coffee'),
      'user_settings': require('hotels/components/user_settings/user_settings.js').default,
      'hotel_reviews': require('hotels/components/hotel_page/reviews/reviews.js').default,
      'hotel_proposals': require('hotels/components/hotel_page/proposals/proposals.js').default
    }

    return _merge(super.componentsMap(), hotelComponents)
  }

  specificRequirements () {
    require('hotels/lib/request_processor') // REQUEST REAL DATA
  }
}

if ((/([^:]\/)\/+/).test(window.location.href)) {
  window.location.href = window.location.href.replace(/([^:]\/)\/+/g, '$1')
}

let loadCallback = function () { return new HotelsComponentsInitializer() }
if (window.TPWLCONFIG !== undefined && window.TPWLCONFIG.pageLoaded) loadCallback()
else document.addEventListener('DOMContentLoaded', loadCallback)
