import Device from 'shared/lib/device.js'

export default class {
  constructor(options) {
    const METHODS_TO_BIND = [
      'on',
      'setCenter',
      'setZoom',
      'getBounds',
      'getCenter',
      'easeTo',
      'panBy',
      'once'
    ]

    maplibregl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true // Lazy load the plugin
    );
    this.map = new maplibregl.Map(options);
    this.map.addControl(new maplibregl.NavigationControl({ showCompass: false, showZoom: true }), 'bottom-right');
    this._initBindings(METHODS_TO_BIND, this.map);

    this.map.on('load', () => {
      this._nullFeature = {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": this.map.getCenter() },
        "properties": { shown: false }
      };
      this.hotelsSource = this._createCollectionSource('hotels');
      this.activeHotelSource = this._createMarkerSource('active_hotel');
      this.forcedHotelSource = this._createMarkerSource('forced_hotel');

      this._initLayers();
      this._initEvents();

      this.map.resize();
    });
  }

  setHotels(features) {
    this.hotelsSource.setData({
      "type": "FeatureCollection",
      "features": features,
    });
  }

  setActive(feature) {
    this.activeHotelSource.setData(feature ? feature : this._nullFeature);
  }

  setForced(feature) {
    this.forcedHotelSource.setData(feature ? feature : this._nullFeature);
  }

  addHotelsEventListener(action, callback) {
    this._addLayerEventListener(action, 'hotels', callback);
  }

  addActiveHotelEventListener(action, callback) {
    this._addLayerEventListener(action, 'active_hotel', callback);
  }

  _addLayerEventListener(action, layerName, callback) {
    this.map.on(action, layerName, (event) => {
      let features = this.map.queryRenderedFeatures(event.point, { layers: [layerName] });
      callback(features[0] && features[0].properties.hotel_id);
    });
  }

  _initBindings(methods, source) {
    for (let method of methods) {
      this[method] = source[method].bind(source);
    }
  }

  _createSource(name, options) {
    this.map.addSource(name, options);

    return this.map.getSource(name);
  }

  _createCollectionSource(name) {
    return this._createSource(name, {
      type: 'geojson',
      data: {
        "type": "FeatureCollection",
        "features": []
      }
    });
  };

  _createMarkerSource(name) {
    return this._createSource(name, {
      type: 'geojson',
      data: this._nullFeature
    });
  }

  _initLayers() {
    let radius = Device.isTouch() ? 10 : 6;

    this._createCicleLayer(
      'hotels',
      {
        "circle-color": window.TPWLCONFIG.color_scheme.btn_bg,
        "circle-radius": radius,
        "circle-stroke-width": 1,
        "circle-stroke-color": window.TPWLCONFIG.color_scheme.btn_text
      },
      [
        "all",
        ["==", 'has_price', true],
        ["==", 'shown', true]
      ]
    );

    this._createCicleLayer(
      'active_hotel',
      {
        "circle-color": window.TPWLCONFIG.color_scheme.bg,
        "circle-radius": radius,
        "circle-stroke-width": 1,
        "circle-stroke-color": window.TPWLCONFIG.color_scheme.text_contrast
      },
      ["==", 'shown', true]
    );

    this._createCicleLayer(
      'forced_hotel',
      {
        "circle-color": window.TPWLCONFIG.color_scheme.bg,
        "circle-radius": radius * 2,
        "circle-stroke-width": 2,
        "circle-stroke-color": window.TPWLCONFIG.color_scheme.text_contrast
      },
      ["==", 'shown', true]
    );
  }

  _createCicleLayer(source, paint, filter = []) {
    this.map.addLayer({ id: source, type: "circle", source, filter, paint });
  }

  _enterCallback(cursor) {
    return () => {
      this.map.getCanvas().style.cursor = cursor;
    }
  }

  _leaveCallback() {
    this.map.getCanvas().style.cursor = '';
  }

  _initEvents() {
    const leaveCallback = this._leaveCallback.bind(this)
    this.map.on('mouseenter', 'active_hotel', this._enterCallback('pointer'));
    this.map.on('mouseleave', 'active_hotel', leaveCallback);
    this.map.on('mouseleave', 'hotels', leaveCallback);
    this.map.on('mouseleave', 'forced_hotel', leaveCallback);
  }
}
