import DOMComponent from 'shared/components/base'
import dispatcher from 'shared/lib/dispatcher'
import Params from 'hotels/components/params_parser/search_params.js'
import PopupScroller from 'shared/lib/popup_scroller.js'
import Swiper from 'shared/lib/swiper.js'
import Device from 'shared/lib/device.js'
import ResizeWatcher from 'shared/lib/resize_watcher.coffee'
import Template from './search_results.monk'
import filters from 'shared/lib/filters.coffee'
import metrics from 'shared/lib/metrics.coffee'
import paramsPresenter from 'hotels/components/params_parser/params_presenter'
import stickySetter from 'shared/lib/sticky_setter.js'

export default class SearchResults extends DOMComponent {
  static defaultOptions() {
    return {
      name: 'serch_results',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          // '.search_results-wrapper-filters': {
          //   'background': window.TPWLCONFIG.color_scheme.body_bg
          // },
          '.filters-header': {
            'background': window.TPWLCONFIG.color_scheme.bg
          },
          // Buy button
          '.main_gate-button': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '.main_gate-button:hover': { 'background-color': window.TPWLCONFIG.color_scheme.button_hover },
          '.card-loader .card_spinner div': {
            'background': window.TPWLCONFIG.color_scheme.bg
          },
          '.card.card--selected': {
            'border-color': window.TPWLCONFIG.color_scheme.link
          },
          '.card-loader .spinner div': {
            'background': window.TPWLCONFIG.color_scheme.bg
          },
          '.search_results-mobile_sticky': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.preroll-hotel_trigger span': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.card-gates_list-item .card-gates_list-item-deeplink': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.card-gates_list-item .card-more_proposals': {
            'color': window.TPWLCONFIG.color_scheme.link
          }
        }
      },
      render: { template: Template, options: { filters } },
      state: {
        hotels: {},
        firstHotelsPart: {},
        lastHotelsPart: {},
        destination: Params.params.destination || '',
        currency: 'rub',
        proposals: {},
        googletagIsAvailable: window.googletag != undefined,
        yaAdfoxIsAvailable: window.Ya != undefined && window.Ya.adfoxCode != undefined
      }
    }
  }

  constructor(node, options = {}) {
    super(node, options)

    this.shownChunkSize = 10
    this.shownCount = this.shownChunkSize
    this.sorting = 'by_price'
    this.index = null
    this.searchFinished = false
    this.filtering = false
    this.location = false
    this.scrollingToFirstCard = true
    this.discounts = {}
    if (ResizeWatcher.isMobile) {
      stickySetter.addElement(this.view.createDocument().querySelector('[role="mobile_sticky"]'),
        'search_results-mobile_sticky--sticky', null, 50, true, true)
    }

    if (Device.isTouch() && !Device.isDesktopSize()) {
      let swipeTriggerNode = this.view.querySelector('[role="swipe-trigger"]')
      let swipeTargetNode = this.view.querySelector('[role="swipe-target"]')
      this.swiper = new Swiper(swipeTriggerNode, swipeTargetNode, 'right', 280, 'filters--shown', 'filters_popup_opened', 'filters_popup_closed')
    }
    this.toggleState()
  }

  _initEvents(dispatcher) {
    metrics.reach_goal('SERP_VIEW')
    dispatcher.on('locations_updated', (event, { locations }) => {
      if (!this.location && locations[this.locationId]) {
        this.location = locations[this.locationId]
        this.state.destination = this.location.name
        this.refresh()
      }
    })

    dispatcher.on('serp_hotels_changed', (event, { request_id: searchId, hotels, hotel_proposals: proposals, filteredHotels, totalHotels, discounts }) => {
      this.searchId = searchId
      this.state.hotels = hotels
      this.state.firstHotelsPart = hotels.slice(0, 2);
      this.state.lastHotelsPart = hotels.slice(2);
      this.state.shownHotels = hotels.length
      this.state.filteredHotels = filteredHotels
      this.state.totalHotels = totalHotels
      this.refresh()
      if (this.scrollingToFirstCard) dispatcher.send("card_rendering")
      this.scrollingToFirstCard = false
      PopupScroller.closePopup()
    })

    dispatcher.on('map_hotels_updated', (event, { request_id: searchId, filteredHotels, totalHotels }) => {
      this.searchId = searchId
      this.state.filteredHotels = filteredHotels
      this.state.totalHotels = totalHotels
      this.refresh()
    })

    dispatcher.on('params_restored', (event, { params: { destination, locations_ids: locationIds } }) => {
      this.state.destination = destination
      this.locationId = locationIds !== undefined ? locationIds[0] : false
      this.state.params = paramsPresenter
      this.refresh()
    })

    dispatcher.on('currency_updated', (event, currency) => {
      this.state.currency = currency
      this.refresh()
    })

    dispatcher.on('start_search', (event, { request_id: searchId }) => {
      this.searchId = searchId
      this.searchFinished = false
      this.toggleState()
    })

    dispatcher.on('search_finished', (event, { request_id: searchId }) => {
      this.searchFinished = true
      if (Object.keys(this.state.proposals).length === 0) metrics.reach_goal('PROPOSALS_IS_NULL', { search_id: searchId })
      this.toggleState()
    })

    dispatcher.on('request_started', (event, { source, showLoader }) => {
      if (showLoader) {
        this.filtering = true
        this.toggleState()
      }
    })

    dispatcher.on('request_finished', () => {
      this.filtering = false
      this.toggleState()
    })

    dispatcher.on('close_gallery', () => {
      dispatcher.send('modal_closed', { id: 'gallery' }) // Hack to close gallery as a popup in iframe
    })
  }

  _initDOMEvents(view) {
    super._initDOMEvents(view)
    this.wrapperNode = view.querySelector('[role="search_results-wrapper"]')
    view.on('click', '[role="fade"]', () => {
      dispatcher.send('filters_popup_closed')
    })
    view.on('click', '[role="params_edit_trigger"]', () => {
      window.scrollTo(0, 0) // быстрое решение для ситуации, когда на узких экранах приложение загрузилось, проскролилось и форма в неактивной части эрана скрыта и фокусить некуда
      document.querySelector('#tpwl-main-form input[name="destination"]').focus()
    })

    view.on('click', '[role="gallery-full-screen-toggler"]', () => {
      // dispatcher.send('modal_opened', {id: 'gallery'})
      metrics.reach_goal('SERP_GALLERY_FULLSCREEN')
    })

    view.on('click', '[role="reset_filters"]', () => {
      this._dispatcher.send('reset_filters')
    })
  }

  toggleState() {
    window.requestAnimationFrame(() => {
      if (!this.searchFinished) { this.wrapperNode.classList.add('search-results--loading') } else { this.wrapperNode.classList.remove('search-results--loading') } // Fix for IE 11
      // this.wrapperNode.classList.toggle('search-results--loading', !this.searchFinished)
      if (this.filtering) {
        this.wrapperNode.classList.add('search-results--filtering')
      } else {
        if (this.wrapperNode.classList.contains('search-results--filtering')) {
          this.wrapperNode.classList.remove('search-results--filtering')
          this.wrapperNode.classList.add('search-results--filtering_finished')
          setTimeout(() => this.wrapperNode.classList.remove('search-results--filtering_finished'), 200)
        }
      }
    })
  }
}
