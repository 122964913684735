import _reduce from 'lodash/reduce'
import Params from './search_params'
import dispatcher from 'shared/lib/dispatcher'
import filters from 'shared/lib/filters.coffee'

let presenter = {
  init (params) {
    this.nights_of_stay = filters.localisedStayRange(params.check_in, params.check_out)
    this.booking_period_text = this.currentDatesFormatted(params)
    this.guests = this.guestsFormatted(params)
  },

  currentDatesFormatted (params = Params.params) {
    return Params.createDatesFormatted(params.check_in, params.check_out)
  },

  guestsFormatted (params) {
    let guests = 0
    params.rooms.forEach((room) => {
      let children = 0

      if (room.children.length) {
        children = _reduce(room.children, (count, val) => { return count + 1 }, 0)
      }

      guests += room.adults + children
    })

    return filters.pluralize(guests, __('hotel_page.guests'))
  }
}

dispatcher.on('params_restored', function (event, params) {
  presenter.init(params.params)
})

export default presenter
