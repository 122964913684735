import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'

export default class HotelFacilitiesFilter extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'hotel_facilities_filter',
      state: {
        collapsed: false,
        title: __('filters.hotel_facilities'),
        labels: (id) => dictionary.getName('hotels_amenities', id)
      }
    }, super.defaultOptions())
  }
}
