import _extend from 'lodash/extend'
import _isEqual from 'lodash/isEqual'
import Device from 'shared/lib/device.js'
import Photoswipe from 'hotels/components/photoswipe/photoswipe.js'
import Template from './gallery.monk'
import _dispatcher from 'shared/lib/dispatcher'
import photoUtils from 'shared/lib/photo_utils.js'
import preloader from 'hotels/lib/preloader.coffee'

const minWidth = 245
export default class Gallery extends Template {
  constructor () {
    super()

    if (!this.filters) this.filters = {}

    this.state = {photosIds: [], currentItem: 0}
    this.slideTo = 0
    this.on('click', '[role="hotels_gallery_prev"]', () => { this._slide(false) })
    this.on('click', '[role="hotels_gallery_next"]', () => { this._slide(true) })
    this.on('click', '[role="gallery-full-screen-toggler"]', () => {
      let width = window.innerWidth < 1024 ? window.innerWidth : 1024
      width = width < minWidth ? minWidth : width
      let items = photoUtils._getPhotoItemsByIds(this.state.photosIds, width, Math.round(width / 4 * 3))
      if (items.length) {
        const params = {
          items: items,
          options: {
            index: this.state.currentItem,
            bgOpacity: 0.85,
            closeOnScroll: false,
            shareEl: false,
            preload: [1, 2]
          },
          callback: this.setByIndex.bind(this)
        }
        _dispatcher.send('show_gallery', params)
      }
    })
    this.initEvents()
  }

  update (state) {
    if (!state.width || !state.height) {
      let parent = this.createDocument().parentNode
      state.width = parent.offsetWidth < minWidth ? minWidth : parent.offsetWidth
      state.height = parent.offsetHeight
    }

    if (_isEqual(state.photosIds, this.state.photosIds) && !state.galleryForce) return false
    this.state.currentItem = 0
    this.state.len = state.photosIds.length
    this.state = _extend(this.state, state)
    this.state.preloadFirst = true
    this.state.selectedURLs = ['', '', '']
    super.update(this.state)

    preloader.preload(this._URLById(this.state.photosIds[0]), () => {
      this._setSelectedURLs()
      preloader.preload(this.state.selectedURLs[0], () => {}, 0)
      preloader.preload(this.state.selectedURLs[2], () => {}, 0)
      super.update(this.state)
      this.state.preloadFirst = false
    })
  }

  setByIndex (index) {
    this.state.currentItem = index
    this._setSelectedURLs()
    super.update(this.state)
  }
  _setSelectedURLs () {
    this.state.selectedURLs = [
      this._URLById(this.state.photosIds[this._shiftIndex(this.state.currentItem, -1, this.state.len)]),
      this._URLById(this.state.photosIds[this.state.currentItem]),
      this._URLById(this.state.photosIds[this._shiftIndex(this.state.currentItem, 1, this.state.len)])
    ]
  }

  _URLById (id) {
    return `https://photo.hotellook.com/image_v2/crop/${id}/${Device.pixelsWithRatio(this.state.width)}/${Device.pixelsWithRatio(this.state.height)}.auto`
  }

  _shiftIndex (current, step, len) {
    step = step % len
    let next = current + step
    if (next < 0) next = len + next
    if (next >= len) next = next - len
    return next
  }

  _preloadURLs (step, count) {
    for (let i = 1; i <= count; i++) {
      preloader.preload(this._URLById(
        this.state.photosIds[this._shiftIndex(this.state.currentItem, i, this.state.len)]
      ), function () {}, 0)
    }
  }

  _slide (left = true) {
    let step = left ? 1 : -1
    this.slideTo += step
    if (this.sliding || this.state.preloadFirst) return false
    this.sliding = true

    this.state.currentItem = this._shiftIndex(this.state.currentItem, step, this.state.len)
    let modifier = `hotels-gallery--slided-${left ? 'left' : 'right'}`
    let node = this.createDocument()
    node.classList.add(modifier)
    this._setSelectedURLs()
    this._preloadURLs(step, 5)

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.slideTo -= step
        node.classList.remove(modifier)
        super.update(this.state)
        this.sliding = false

        if (this.slideTo !== 0) {
          let left = this.slideTo > 0
          this.slideTo += left ? -1 : 1
          this._slide(left)
        }
      })
    }, 200)
  }

  initEvents () {
    _dispatcher.on('card_swipe', (event, data) => {
      if (this.createDocument() === data.node) {
        this._slide(data.position === 'left')
      }
    })
  }
}
