import _keys from 'lodash/keys'
import _uniq from 'lodash/uniq'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'

import DOMComponent from 'shared/components/base'
import Device from 'shared/lib/device.js'
import Hotel from './hotel.js'
import MapCircle from './circle'
import Params from 'hotels/components/params_parser/search_params.js'
import Template from './map.monk'
import URLFragment from 'shared/lib/url_fragment.js'
import colorUtils from 'shared/lib/color_utils.js'
import dictionary from 'shared/lib/dictionary.js'
import dispatcher from 'shared/lib/dispatcher'
import filters from 'shared/lib/filters.coffee'
import hotelFilters from 'hotels/components/filters/filters'
import listenManager from 'shared/lib/listen_manager'
import metrics from 'shared/lib/metrics.coffee'
import router from 'shared/lib/router.js'
import MapBox from './mapbox.js'

export default class Map extends DOMComponent {
  static get API_KEY() { return 'ZMp8oippQsvww8HT42wR' }
  static defaultOptions() {
    return {
      name: 'map',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.map__button': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.map-button_container:hover .map__button': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.bg, '#ffffff')
          },
          '.map__close_button': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.map-find_button': {
            'color': window.TPWLCONFIG.color_scheme.bg
          },
          '.map-find_button:before': {
            'border-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.map-button_container:hover .map__close_button': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.bg, '#ffffff')
          },
          '.map-hotel-link__button': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg,
            'color': window.TPWLCONFIG.color_scheme.btn_text
          },
          '.map-hotel-link__button:hover': {
            'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.btn_bg, '#ffffff')
          }
        }
      },
      render: { template: Template, options: { filters } },
      state: {
        destination: encodeURIComponent(Params.params.destination),
        api_key: this.API_KEY,
        forcedCircle: false,
        activeHotel: false,
        forcedHotel: false,
        initialized: false,
        coords: { lat: 0, lon: 0 }
      },
      callbackName: 'TPWLMapCallback',
      shown: false
    }
  }

  constructor(node, options = {}) {
    super(node, options)
    this.hotels = {}
    this.features = []
    this.hotelIds = []
    this.actualHotelIds = []
    this.clientRect = {}
    this.state.page = router.page

    this.centerInitialized = false
    this.searchFinished = false
    this.deactualizeHotelsPromise = false
    this.initialForcedId = Params.params.hotels_ids.length && Params.params.hotels_ids[0]
    this.circleCoef = Device.isTouch() ? 0.45 : 0.6;

    this.mapNode = this.view.querySelector('[role="map-container"]')
    if (!Params.params.hotels_ids.length) { this.mapNode.classList.add(`map-container--filters`) }
    this.refresh()
    // console.log(dictionary.locations[Params.params.locations_ids[0]].center_coords)
  }

  get forced() { return this.state.forcedHotel }
  set forced(hotel) {
    if (this.state.forcedHotel || hotel) this.map.setForced(hotel && hotel.feature);
    this.state.forcedHotel = hotel
    this.refresh()
  }

  get active() { return this.state.activeHotel }
  set active(hotel) {
    if (this.state.activeHotel || hotel) this.map.setActive(hotel && hotel.feature);
    this.state.activeHotel = hotel;
    this.refresh();
  }

  _initEvents(dispatcher) {
    dispatcher.on('locations_updated', (event, { locations }) => {

      if (!this.city && locations[this.locationId]) {
        this.city = locations[this.locationId]
        this.state.coords = this.city.center_coords || this.state.coords
        this._initCenter()
        this._initCircle()
        this.refresh()
      }
    })

    dispatcher.on('filters_state_updated', (event, state, name) => {
      const filterState = state['distance_to']
      if (this.state.initialized && name === 'distance_to_filter') {
        if (!filterState) {
          this.state.forcedCircle && this._deleteCircle()
          return
        }
        const radius = filterState.radius
        const point = _isEmpty(filterState.point)
          ? this.center : { lat: filterState.point.lat, lng: filterState.point.lon }
        if (!this.state.forcedCircle) {
          this.circle = MapCircle(this.map, point, radius * 1000)
          this.state.forcedCircle = true
          return
        }
        this.circle.setCenter(point)
        this.circle.setRadius(radius * 1000)
        // this.circle.upadateMarkerPosition()
      }
      if (this.deactualizeHotelsPromise) {
        this.deactualizeHotelsPromise.cancel()
        this.deactualizeHotelsPromise = false
      }
      setTimeout(() => { this._deactualizeHotels() }, 200)
    })

    dispatcher.on('search_finished', (event, { request_id: searchId }) => {
      this.searchId = searchId
      this.searchFinished = true
      if (this.deactualizeHotelsPromise) {
        this.deactualizeHotelsPromise.cancel()
        this.deactualizeHotelsPromise = false
      }
      this._deactualizeHotels()
    })

    dispatcher.on('params_restored', (event, params) => {
      this.options.shown = (URLFragment.state.l !== undefined && URLFragment.state.l['type'].indexOf('wide') !== -1) ||
        (URLFragment.state.mds && URLFragment.state.mds.indexOf('hotels_map') !== -1)
      if (this.options.shown) URLFragment.update({ l: { type: [] } })

      if (params.params.locations_ids !== undefined) {
        this.locationId = params.params.locations_ids[0]
        if (dictionary.locations[this.locationId]) this.city = dictionary.locations[this.locationId]
      }

      this.state.currency = params.params.currency
      if (this.options.shown) { this._initMap() }
    })
    dispatcher.on('page_changed', (event, { old: oldPage, new: newPage }) => {
      this.requestForMapOnHotelPage = (oldPage === 'hotel_page' && newPage === 'map')
      newPage === 'map' ? this._showMap() : this._hideMap()
    })
  }

  _initDOMEvents(view) {
    this.togglerNode = view.querySelector('[role="map-toggler"]')
    this.togglerNode.addEventListener('click', (event) => {
      if (router.page === 'map') {
        router.goBack()
      } else {
        metrics.reach_goal('MAP_OPEN')
        router.page = 'map'
        this.circle && this.map.map.fitBounds(this.circle.map.getBounds())
      }
    })
    view.querySelector('[role="find_hotel-toggler"]').addEventListener('click', (event) => {
      if (this.state.forcedCircle) {
        dispatcher.send('reset_filter', 'distance_to')
        return
      }
      const radius = this._createBoundsRadius()
      this.circle = MapCircle(this.map, this.map.getCenter(), radius * 1000)
      this.state.forcedCircle = true
      metrics.reach_goal('MAP_DRAW_CIRCLE_BY_BUTTON')
    })
    listenManager(this.togglerNode, 'mouseenter', () => { metrics.reach_goal('MAP_MOUSEENTER') }).once()
    view.on('click', '[role="hotel_unforce"]', () => { if (this.forced) this.forced.unforce() })
  }
  _createBoundsRadius() {
    let lat1, lon1, lat2, lon2;
    [[lon1, lat1], [lon2, lat2]] = this.map.getBounds().toArray();

    const R = 6371; // km
    const dLat = this._toRad(lat2 - lat1);
    const dLon = this._toRad(lon2 - lon1);
    lat1 = this._toRad(lat1);
    lat2 = this._toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    let radiusViweport = Math.round(100 * ((distance / 2) * this.circleCoef)) / 100;
    return radiusViweport;
  }

  _toRad(val) {
    return val * Math.PI / 180;
  }

  _initMap() {
    if (this.state.initialized) return false
    this.state.initialized = true
    metrics.reach_goal('MAP_VIEW')
    this._loadAPIScript()
  }

  _deleteCircle() {
    this.circle.deleteFromMap()
    this.circle = null
    this.state.forcedCircle = false
  }

  _deactualizeHotels() {
    if (this.actualHotelIds === undefined) return false
    for (var i = 0; i < this.actualHotelIds.length; i++) { this.hotels[this.actualHotelIds[i]].deactualize() }
    this.actualHotelIds = []

    if (this.active) this.active.loadInfo()
    if (this.forced) this.forced.loadInfo()
  }

  _showMap() {
    this._initMap()
    dispatcher.send('modal_opened', { id: 'hotels_map', closeFunc: () => { router.goBack() }, openFunc: () => { router.page = 'map' } })
    let resizeCallback = (e) => {
      let expiredSearchNode = document.querySelector('.expired-search-plate')
      if (expiredSearchNode) expiredSearchNode.style.setProperty('top', '0px')

      Device.lockScroll()

      if ('mapbox' in window && this.map !== undefined) this.map.resize(e)
    }

    this._debouncedResizeCallback = _debounce(resizeCallback, 50)
    window.addEventListener('resize', this._debouncedResizeCallback)
    resizeCallback()
    this._initCenter()
    this._initCircle()
  }

  _initCircle() {
    if (this.state.forcedCircle) return false
    const distanceToState = hotelFilters.getState()['distance_to']
    if (distanceToState && this.center) {
      const radius = distanceToState.radius
      const center = _isEmpty(distanceToState.point)
        ? this.center : { lat: distanceToState.point.lat, lng: distanceToState.point.lon }
      this.circle = MapCircle(this.map, center, radius * 1000)
      this.state.forcedCircle = true
      return true
    }
  }
  _initCenter() {
    if (!this.map || this.centerInitialized) return false
    if (this.initialForcedId) {
      if (this.hotels[this.initialForcedId]) {
        let hotel = this.hotels[this.initialForcedId]
        hotel.force()
        this.center = hotel.pos
        this.map.setCenter(this.center)
        this.map.setZoom(15)
        this.initialForcedId = false
        this.centerInitialized = true
      }
    } else {
      if (this.city && !this.map.manualUse) {
        this.center = { lat: this.city.center_coords.lat, lng: this.city.center_coords.lon }
        this.map.setCenter(this.center)
        this.centerInitialized = true
      }
    }
  }

  _hideMap() {
    dispatcher.send('modal_closed', { id: 'hotels_map' })
    if (this._debouncedResizeCallback) window.removeEventListener('resize', this._debouncedResizeCallback)
    this.forcedCircle && this.deleteCircle()
    Device.unlockScroll()
  }

  _preloadPins() {
    this._dispatcher.send('map_preload_pins', {}, 'map')
  }

  _loadAPIScript() {
    let style = document.createElement('link')
    style.setAttribute('href', "https://api.tiles.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.css")
    style.setAttribute('rel', "stylesheet")
    style.setAttribute('type', "text/css")
    document.head.appendChild(style)

    let script = document.createElement('script')
    script.onload = () => { this._mapScriptsLoaded() }
    document.head.appendChild(script)
      script.src = 'https://cdn.maptiler.com/maplibre-gl-js/v2.2.0-pre.2/maplibre-gl.js'
  }

  _updateOrCreateHotel(id, params) {
    let hotel = this.hotels[id]
    let changed = false;
    if (hotel === undefined) {
      hotel = new Hotel(params, id, this)
      this.hotelIds.push(id)
      this.hotels[id] = hotel
      this.features.push(hotel.feature)
      changed = true;
    } else if (params !== undefined) {
      changed = hotel.update(params)
    }

    return [hotel, changed]
  }

  _mapScriptsLoaded() {
    if (!this.requestForMapOnHotelPage) this._preloadPins()
    window.map = this.map = new MapBox({
      container: this.view.querySelector('#tpwl-map'),
      style: `https://api.maptiler.com/maps/bright/style.json?key=${this.state.api_key}`,
      zoom: 14
    })

    this.map.on('load', () => {
      this._initMapHotelsDataListener();
      let mouseCallback = (id) => {
        if (this.active && this.active.id === id) return true;
        let hotel = this.hotels[id];
        hotel.activate();
      }
      this.map.addHotelsEventListener('mouseenter', mouseCallback);
      this.map.addHotelsEventListener('mousemove', mouseCallback);

      let clickListener = (Device.isTouch() ? this.map.addHotelsEventListener : this.map.addActiveHotelEventListener)
      clickListener = clickListener.bind(this.map)
      clickListener('click', (id) => {
        let hotel = this.hotels[id];
        hotel.force();
      });

      this.map.addHotelsEventListener('mouseleave', () => {
        if (this.active) this.active.deactivate();
      });
    });

    if (this.options.shown) { router.page = 'map' }
    if (router.page === 'map') this._debouncedResizeCallback()
  }

  _initMapHotelsDataListener() {
    let throttledDeactualizeHotels = _throttle(() => {
      this.deactualizeHotelsPromise = false
      this._deactualizeHotels()
    }, 10000)

    this._dispatcher.on('map_hotels_updated', (event, { request_id: searchId, hotels_map: hotelsMap }) => {
      this.searchId = searchId
      if (!this.state.initialized) this._initMap()
      if (!this.searchFinished) throttledDeactualizeHotels()
      let ids = _uniq(this.hotelIds.concat(_keys(hotelsMap)))
      let hasChanges = false
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i]
        let params = hotelsMap[id]
        let [hotel, changed] = this._updateOrCreateHotel(id, params)
        let shown = (params !== undefined)
        let shownChanged = (shown != hotel.shown)
        hasChanges = hasChanges || changed || shownChanged
        if (shownChanged) hotel.shown = shown
      }

      if (this.forced && !this.forced.shown) this.forced.unforce()
      if (this.active && !this.active.shown) this.active.deactivate()

      this._initCenter()
      this._initCircle()
      this.refresh()

      if (hasChanges) {
        this.map.setHotels(this.features);
      }
    })
  }
}
