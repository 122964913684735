{% import Proposal from './proposal.monk' %}
{% import dictionary from 'shared/lib/dictionary.js' %}
{% import Spinner from 'shared/components/spinner/spinner.monk' %}
{% import paramsPresenter from 'hotels/components/params_parser/params_presenter' %}

<div class="hotel_page-proposals" role="proposals_list">
  {% if hotel.best_group && hotel.best_group.proposal %}
    <div class="hotel_page-proposals-group hotel_page-proposals-group--{{hotel.best_group.items.length}}_items{{ hotel.best_group.photo_id ? '' : ' hotel_page-proposals-group--no_photos'}} hotel_page-proposals-group--best hotel_page-proposals-group--type_id_{{hotel.best_group.proposal.internal_type_id}}">
      <div class="hotel_page-proposals-group-header">
          <div class="hotel_page-proposals-group-header-photos hotel_page-proposals-group-header-photos--best {{ hotel.best_group.photo_id || 'hotel_page-proposals-group-header-photos--hidden'}}" style="{{hotel.best_group.photo_url ? 'background-image: url(' + hotel.best_group.photo_url + ');' : ''}}">
            <div class="hotel_page-proposals-group-fotter">
              <span class="hotel_page-proposals-group-header-photos__zoom" data-room-id="{{hotel.best_group.room_id}}" role="gallery_toggler"></span>
              <span class="hotel_page-proposals-group-header-photos__count"> {{hotel.best_group.photo_count}}</span>
            </div>
            <div class="hotel_page-proposals-group-title">
              <span class="hotel_page-proposals-group-title__text">
                {% if (hotel.best_group.photo_count) %}
                  {{ __('hotel_page.proposals_group_photos') }}&nbsp;{{hotel.best_group.photo_count}}
                {% else %}
                  {{ __('hotel_page.proposals_group_no_photos') }}
                {% endif %}
              </span>
            </div>
          </div>
        <div class="hotel_page-proposals-group-header-title">
          {{__('hotel_page.best_proposal_title')}}
        </div>
      </div>
      <div class="hotel_page-proposals-group-best_proposal" role="link_to_book_now">
        <Proposal hotel="{{ hotel }}" bestGroup="{{ hotel.best_group }}" direction="{{direction}}" proposal="{{hotel.best_group.proposal}}" currency="{{ currency }}" targetLink="{{ targetLink }}"/>
      </div>
    </div>
  {% endif %}
  <div class="hotel_page-proposals-container">
    {% for group of hotel.grouped_proposals %}
      <div class="hotel_page-proposals-group hotel_page-proposals-group--{{group.items.length}}_items{{ group.photo_id ? '' : ' hotel_page-proposals-group--no_photos'}}">
        <div class="hotel_page-proposals-group-header">
            <div class="hotel_page-proposals-group-header-photos {{ group.photo_id || 'hotel_page-proposals-group-header-photos--hidden'}}" role="{{ group.photo_count ? 'gallery_toggler' : '' }}" data-room-id="{{group.room_id}}" style="{{group.photo_url ? 'background-image: url(' + group.photo_url + ');' : ''}}">
              <div class="hotel_page-proposals-group-fotter">
                <span class="hotel_page-proposals-group-header-photos__zoom" ></span>
                <span class="hotel_page-proposals-group-header-photos__count"> {{group.photo_count}}</span>
              </div>
              <div class="hotel_page-proposals-group-title">
                <span class="hotel_page-proposals-group-title__text">
                  {% if (group.photo_count) %}
                    {{ __('hotel_page.proposals_group_photos') }}&nbsp;{{group.photo_count}}
                  {% else %}
                    {{ __('hotel_page.proposals_group_no_photos') }}
                  {% endif %}
                </span>
              </div>
            </div>
          <div class="hotel_page-proposals-group-header-title">
            {% if group.room_id %}
              {{ dictionary.getName('room_types', group.room_id) }}
            {% else %}
              {{ __('hotel_page.other_proposals') }}
            {% endif %}
            <span class="hotel_page-proposals-group-header-title__deals">
              {{ group.items.length | pluralize(__('hotel_page.deals')) }}
            </span>
          </div>
        </div>
        <div class="hotel_page-proposals-group-fake_pile"></div>
        <div class="hotel_page-proposals-group-block">
          <ul class="hotel_page-proposals-group-list">
            {% for proposal of group.items %}
              <li class="hotel_page-proposals-group-list__item">
                <Proposal hotel="{{ hotel }}" direction="{{direction}}" proposal="{{proposal}}" currency="{{ currency }}"/>
              </li>
            {% endfor %}
          </ul>
          <div class="hotel_page-proposals-group-expander hotel_page-link-with-arrow" role="proposals_group_expander">
            <span class="hotel_page-proposals-group-expander__expand_text">
              {{ __('hotel_page.show_all_rooms')}}:&nbsp;{{group.items.length}}
            </span>
            <span class="hotel_page-proposals-group-expander__collapse_text">
              {{ __('hotel_page.collapse_rooms')}}
            </span>
          </div>
          <hr class="hotel_page-proposals-group__hr"/>
        </div>
      </div>
    {% endfor %}
  </div>
  {% if !hotel.proposal %}
    <div class="hotel_page-proposals-container__loader">
      <Spinner/>
      <p>{{ __('hotel_page.seeking_rooms') }}</p>
    </div>
  {% endif %}
  <div class="hotel_page-proposals-header">
    <div class="hotel_page-proposals-header__mobile_back_button" role="hotel_page-mobile-back"> </div>
    <div class="hotel_page-proposals-header-cities">{{ __('hotel_page.available_rooms') }}</div>
    <div class="hotel_page-proposals-header-dates">
      {{ paramsPresenter.booking_period_text }},&nbsp;{{ paramsPresenter.guests }}
    </div>
  </div>
</div>
