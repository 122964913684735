import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'

export default class PropertyTypesFilter extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'property_types_filter',
      state: {
        collapsed: false,
        title: __('filters.property_types'),
        labels: (id) => dictionary.property_types[id]
      }
    }, super.defaultOptions())
  }
}
