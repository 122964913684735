{% import Spinner from 'shared/components/spinner/spinner.monk' %}
{% import Review from './review.monk' %}

<section class="hotel_page-reviews {{ expanded ? 'hotel_page-reviews--expanded' : ''}} {{ stats[""] > 0 ? '' : 'hotel_page-reviews--hidden' }}">
  <header class="hotel_page-reviews__header">
    <div class="hotel_page-proposals-header__mobile_back_button" role="hotel_page-mobile-back"> </div>
    <h1>{{ __('hotel_page.reviews_comments.header') }}</h1>
  </header>
  <ul class="hotel_page-reviews-tabs">
    {% for id of sortedIds %}
      {% if gates[id] %}
        <li class="hotel_page-reviews-tabs__tab {{ id == currentGate ? 'hotel_page-reviews-tabs__tab--active' : ''}} hotel_page-reviews-tabs__tab--{{ gates[id].name }}" data-gate="{{ id }}" data-name="{{ gates[id].beauty_name }}" role="reviews_tab">
          <span>{{ gates[id].beauty_name }}</span> {{ stats[id] }}
        </li>
      {% endif %}
    {% endfor %}
  </ul>
  <div class="hotel_page-reviews-list-wrapper">
    {% if (currentGate == 2) %}
      <div class="hotel_page-reviews-booking-info">
        <div class="hotel_page-reviews-booking-info__verified">
          <span>
            {{ __('hotel_page.booking_verification.reviews')}}
          </span>
          {{ __('hotel_page.booking_verification.real')}}
        </div>
        <div class="hotel_page-reviews-booking-info__powered">
          {{ __('hotel_page.booking_verification.by')}}
        </div>
      </div>
    {% endif %}
    <ul>
      {% for id, __ of reviews_by_gate[currentGate] %}
        <Review id="{{ id }}" review="{{ reviews[id] }}" textKeys="{{textKeys}}" currentGate="{{currentGate}}"/>
      {% endfor %}
    </ul>

    {% if gates[currentGate].url %}
      <div class="hotel_page-reviews-deeplink">
        <a href="{{gates[currentGate].url}}" target="_blank">
          {{ __('hotel_page.read_all_reviews')}}&nbsp;{{gates[currentGate].beauty_name}}
        </a>
      </div>
    {% endif %}
    <div class="hotel_page-reviews-expander-wrapper {{ counts[currentGate] >= stats[currentGate]  ? 'hotel_page-reviews-expander--hidden' : ''}}">
      <div class="hotel_page-reviews-expander" role="reviews_expander">
        {{ __('hotel_page.reviews_comments.show_all_reviews')}}
      </div>
      <div class="hotel_page-reviews-expander-spinner">
        <Spinner />
      </div>
    </div>
  </div>

  {% if stats[currentGate] > 1 %}
    <div class="hotel_page-reviews-expander-mobile" role="reviews_mobile_expander">
      <span class="hotel_page-reviews-expander-mobile__text hotel_page-link-with-arrow">{{ __('hotel_page.reviews_comments.show_all_reviews')}}&nbsp;({{ stats[currentGate] }})</span>
    </div>
  {% endif %}
</section>
