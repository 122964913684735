import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'

let defaultLabels = {
  '0': __('filters.no_stars'),
  '1': '<span class="filter-stars filter-stars--1"></span>',
  '2': '<span class="filter-stars filter-stars--2"></span>',
  '3': '<span class="filter-stars filter-stars--3"></span>',
  '4': '<span class="filter-stars filter-stars--4"></span>',
  '5': '<span class="filter-stars filter-stars--5"></span>'
}

export default class StarsFilter extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'stars_filter',
      state: {
        collapsed: false,
        title: __('filters.stars'),
        labels: (id) => defaultLabels[id],
        keys: ['5', '4', '3', '2', '1', '0'],
        'options': {
          '0': 0,
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0
        }
      },
      defaultFilterState: {
        '0': true,
        '1': true,
        '2': true,
        '3': true,
        '4': true,
        '5': true
      },
      filterState: {
        '0': true,
        '1': true,
        '2': true,
        '3': true,
        '4': true,
        '5': true
      }
    }, super.defaultOptions())
  }

  _keysSortFunction (key) {
    return -parseInt(key)
  }
}
