import ExpiredSearch from 'shared/components/expired_search/expired_search.js'
import metrics from 'shared/lib/metrics.coffee'

const SEARCH_EXPIRED_TIMEOUT = 60 * 1000 * 15 // 15min

export default class HotelsExpiredSearch extends ExpiredSearch {
  static reloadCallback () {
    let newHref = window.location.href.replace(/&?searchId=[^&]+/, '')
    if (newHref === window.location.href) {
      window.location.reload()
    } else {
      window.location.href = newHref
    }
  }

  _initEvents (dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('start_search', (event, {request_id, params}) => {
      this.hide()
      this.state.params = params
      this.changeTTL(SEARCH_EXPIRED_TIMEOUT)
    })

    dispatcher.on('expiration_ttl_changed', (event, msecTTL) => {
      msecTTL = msecTTL > 0 ? msecTTL : 1
      this.changeTTL(msecTTL)
    })

    dispatcher.on('filters_state_updated', (event, state) => {
      if (!this.errors) { this.hide() }
      document.body.classList.remove('TPWL-widget--search_expired')
    })

    dispatcher.on('search_expired', () => {
      metrics.reach_goal('RESULTS_EXPIRED')
    })
  }

  _initDOMEvents (view) {
    view.on('click', '[role="informer-restart-search-trigger"]', this.constructor.reloadCallback)
  }
}
