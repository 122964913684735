{% if(similarHotels.length) %}
<section class="hotel_page-similar_hotels">
  <header class="hotel_page-similar_hotels__title">
    {{ __('hotel_page.similar_hotels.title') }}&nbsp;{{ location && location.declensions && location.declensions.length ? location.declensions[7] : __('hotel_header.in') + " " + location.name }}
  </header>

  <div class="hotel_page-similar_hotels-card_wrapper">
  {% for hotel of similarHotels %}
    <div class="hotel_page-similar_hotels-card">
      <div class="hotel_page-similar_hotels-photo" style="{{ hotel.photo ? 'background-image: url(' + hotel.photo + ')' : '' }}"></div>
      {% if hotel.rating > 0 %}
        <div class="hotel_page-similar_hotels-rating hotel_page-similar_hotels-rating--green">
          {{ hotel.rating.toFixed(1) }}
        </div>
      {% endif %}
      <div class="hotel_page-similar_hotels-info">
        <div class="hotel_page-similar_hotels-stars hotel_page-similar_hotels-stars--{{ hotel.stars }}"></div>
        <address class="hotel_page-similar_hotels-location">{{ hotel.district }}</address>
      </div>
      <div class="hotel_page-similar_hotels-name" title="{{ hotel.name }}">{{ hotel.name }}</div>
      <a href="{{ hotel.link }}" target="_blank" class="hotel_page-similar_hotels-price_button">
        {{ __('hotel_page.similar_hotels.from') }}&nbsp;<span class="currency_font currency_font--{{ currency }}">{{ hotel.proposal.price | split_price }}</span>
      </a>
    </div>
  {% endfor %}
  </div>
</section>
{% endif %}
