{% import dictionary from 'shared/lib/dictionary.js' %}
{% import Gallery from 'hotels/components/gallery/gallery.js' %}
{% import paramsPresenter from 'hotels/components/params_parser/params_presenter' %}

<div data-hotel_id="{{ hotel.id }}" class="map-hotel{{ fullView ? ' map-hotel--full' : '' }}" role="hotel-preview">
    {% if hotel.info %}
      {% if fullView %}
        <div class="map-hotel__unforce_button" role="hotel_unforce"></div>
        <Gallery photosIds="{{ hotel.info.photos_ids }}" galleryForce="{{ hotel.info.galleryForce }}"  width="285" height="210"/>
      {% endif %}

      <div class="map-hotel__type">
        {{ hotel.info.type }}
      </div>

      <div class="map-hotel__stars map-hotel__stars--{{ hotel.info.stars }}">
        <span>{{ __('filters.no_stars') }}</span>
      </div>

      {% if(hotel.info.rating > 0) %}
        <div class="map-hotel__rating map-hotel__rating--green">
          {{ hotel.info.rating | withDecimalDigit }}
        </div>
      {% endif %}

      <a class="map-hotel-page-link" href="{{ hotel.link }}" target="_blank">
        <div class="map-hotel__name">
          {{hotel.info.name}}
            {% if hotel.info.proposal %}
              <span class="map-hotel__price{{ hotel.actual ? '' : ' map-hotel__price--updating' }}">
                {{ __('from').toLowerCase() }} &nbsp;
                <span class="currency_font currency_font--{{ currency }}">
                  {{ hotel.info.proposal.price | split_price }}
                </span>
              </span>
            {% endif %}
        </div>
      </a>

      {% if fullView %}
        <div class="map-hotel-bottom-line">
          {{ hotel.info.district }}

          <div class="map-hotel__stay_period">
            {{ __('card.main_proposal.for') }}&nbsp;{{ paramsPresenter.nights_of_stay }}
          </div>
        </div>

        <a href="{{ hotel.link }}" target="_blank" class="map-hotel-link">
            <div class="map-hotel-link__button">
              {{ __('card.more_proposals') }}
            </div>
        </a>
      {% endif %}
    {% else %}
      {% if fullView %}
        <div class="map-hotel__gallery_stub"></div>
      {% endif %}

      <div class="map-hotel__type_stub"></div>
      <div class="map-hotel__name_stub"></div>

      {% if fullView %}
        <div class="map-hotel__district_stub"></div>
        <div class="map-hotel__button_stub"></div>
      {% endif %}

    {% endif %}
</div>
