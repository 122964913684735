
export default {
  checkForRedirect () {
    let pathRegExp = /\/hotels\/[\w_]{2,4}\/[\w_]+-([\d]+)\/([\w_]+)-([\d]+)\.html/
    let match = pathRegExp.exec(window.location.pathname)
    if (match) {
      let searchAddon = `locationId=${match[1]}&hotelId=${match[3]}&destination=${match[2].replace(/_/g, ' ')}`
      window.location.href = `/hotels/${window.location.search}&${searchAddon}`
      return true
    }
    return false
  }
}
