import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'

export default class ProposalsOptionsFilter extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'proposals_options_filter',
      state: {
        collapsed: false,
        title: __('filters.proposals_options'),
        labels: (id) => dictionary.proposals_options[id]
      }
    }, super.defaultOptions())
  }
  update (data, keys) {
    const specialOptions = [6, 2, 28, 29, 24, 5]
    // room options id description
    // https://github.com/KosyanMedia/hl_api/blob/master/protos/hl/api/common.proto#L32
    const filterRoomOptions = (options, defaultOptions) => {
      const dataKeys = _keys(options)
      const newKeys = defaultOptions.filter(n => _includes(dataKeys, `${n}`)).map(n => `${n}`)
      const filterKeys = dataKeys.filter(n => !_includes(defaultOptions, parseInt(n, 10)))
      return [...newKeys, ...filterKeys]
    }
    keys = filterRoomOptions(data, specialOptions)
    super.update(data, keys)
  }
}
