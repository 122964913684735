import DOMComponent from 'shared/components/base'
import PhotoSwipe from 'photoswipe/dist/photoswipe.js'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default.js'
import Template from './photoswipe.monk'
import dispatcher from 'shared/lib/dispatcher'

export default class Photoswipe extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'photoswipe',
      render: {
        template: Template
      },
      cssx: {
        scope: '.TPWL-widget',
        styles: {}
      },
      state: {

      }
    }
  }

  static show (items, options, callback) { this.instance().show(items, options, callback) }
  static close (items, options) { this.instance().close(items, options) }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    this.refresh()
    this.pswpNode = this.view.createDocument()
  }

  show (items, options = {index: 0, bgOpacity: 0.85, closeOnScroll: false, shareEl: false, preload: [1, 2]}, callback) {
    this.gallery = new PhotoSwipe(this.pswpNode, PhotoSwipeUIDefault, items, options)
    this.gallery.init()
    this.gallery.listen('close', () => dispatcher.send('close_gallery'))
    if (window !== window.top) {
      setTimeout(() => this.gallery.updateSize(true), 100)
      setTimeout(() => this.gallery.updateSize(true), 1000)
    }
  }

  close () {
    !!this.gallery && this.gallery.close()
  }

  getCurrItemIndex () { return this.gallery.getCurrentIndex() }

  _initEvents (dispatcher) {
    dispatcher.on('show_gallery', (event, {items, options, callback}) => {
      this.show(items, options, callback)
      dispatcher.send('modal_opened', {
        id: 'gallery',
        closeFunc: () => dispatcher.send('close_gallery'),
        openFunc: () => this.show()
      })
    })
    dispatcher.on('close_gallery', (event) => { this.close() })
  }
}
