{% import paramsPresenter from 'hotels/components/params_parser/params_presenter' %}

<div class="main_gate-price_info">
  {% if proposal.discount %}
    <div class="main_gate-price_info-discount">
      <span class="main_gate-price_info-discount__percent">
        <span class="main_gate-price_info-discount-percent__text">
          &minus;{{ proposal.discount.change_percentage | abs }}&rlm;%
        </span>
      </span>
      <span class="main_gate-price_info-discount__old_price">
        <span class="currency_font currency_font--{{ currency }}">
            {{ proposal.discount.old_price | split_price }}
        </span>
      </span>
    </div>
  {% endif %}
  <div class="main_gate-price">
    <span class="main_gate-price__from"> {{ __('card.main_proposal.from') }} </span>
    <span class="currency_font currency_font--{{ currency }}">{{ proposal.price | split_price }}</span>
  </div>
  <div class="main_gate-stay_period">
    {{ __('card.main_proposal.for') }}&nbsp;{{ paramsPresenter.nights_of_stay }}
  </div>
</div>

<div class="main_gate-button_details">
  <div class="main_gate-button" role="{{ button_role }}">
    {{ button_text }}
  </div>
  <div class="main_gate__name">{{ __('card.on') }}&nbsp;{{ proposal.gate_name }}</div>
</div>
