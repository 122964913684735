_extend = require('lodash/extend')
class SwipeDetector
  xDown: null
  yDown: null
  wrapper: null
  xDiff: 0
  yDiff: 0
  constructor: (wrapper = document, options) ->
    default_options = {
      callbacks: {},
      sensitivity: 10,
      selector: ''
    }
    @wrapper = wrapper
    @options = _extend {}, default_options, options
    if typeof @wrapper.on == 'function'
      @wrapper.on 'touchstart', @options.selector, (e) => @handleTouchStart(e)
      @wrapper.on 'touchend', @options.selector, (e) => @handleTouchEnd(e)
      @wrapper.on 'touchmove', @options.selector, (e) => @handleTouchMove(e)
    else
      @wrapper.addEventListener 'touchstart', ((e) => @handleTouchStart(e)), false
      @wrapper.addEventListener 'touchend', ((e) => @handleTouchEnd(e)), false
      @wrapper.addEventListener 'touchmove', ((e) => @handleTouchMove(e)), false

  handleTouchStart: (event) ->
    @xDown = event.touches[0].clientX
    @yDown = event.touches[0].clientY

  handleTouchEnd: (event) =>
    if Math.abs(@xDiff) > Math.abs(@yDiff)
      if Math.abs(Math.abs(@xDiff) - Math.abs(@yDiff)) > @options.sensitivity
        if @xDiff > 0
          @options.callbacks.left && @options.callbacks.left()
        else
          @options.callbacks.right && @options.callbacks.right()
    else
      if Math.abs(Math.abs(@yDiff) - Math.abs(@xDiff)) > @options.sensitivity
        if @yDiff > 0
          @options.callbacks.up && @options.callbacks.up()
        else
          @options.callbacks.down && @options.callbacks.down()

    @xDown = null
    @yDown = null
    @xDiff = 0
    @yDiff = 0

  handleTouchMove: (event) ->
    return unless @xDown && @yDown
    xUp = event.touches[0].clientX
    yUp = event.touches[0].clientY
    @xDiff += @xDown - xUp
    @yDiff += @yDown - yUp
    @xDown = xUp
    @yDown = yUp

module.exports = SwipeDetector
