import _defaultsDeep from 'lodash/defaultsDeep'
import _keys from 'lodash/keys'
import Params from 'hotels/components/params_parser/search_params.js'
import Select from 'shared/components/select/select.js'
import Template from 'shared/components/filters/sorting/sorting.monk'
import URLFragment from 'shared/lib/url_fragment.js'
import metrics from 'shared/lib/metrics.coffee'

const SORTINGS = {
  'price': __('sorting.price'),
  'popularity': __('sorting.popularity'),
  'rating': __('sorting.rating'),
  'stars': __('sorting.stars'),
  'distance': __('sorting.distance'),
  'discount': __('sorting.discount')
}

// Возможно немного не в том месте, но пока так
let startSorting = (
    URLFragment.state.s !== undefined && _keys(SORTINGS).indexOf(URLFragment.state.s[0]) !== -1
) ? URLFragment.state.s[0] : (Params.params['geo'] ? 'distance' : 'popularity')

export default class Sorting extends Select {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'sorting',
      render: {
        template: Template,
        options: {
          filters: {
            formatSelected (text) {
              return `<span class="sorting_current_prefix">${__('sorting.by')}</span> ${text.toLowerCase()}`
            }
          }
        }
      },
      state: {
        title: __('sorting.title'),
        selectedId: startSorting,
        options: SORTINGS
      }
    }, super.defaultOptions())
  }

  static getState () {
    let instance = this.instance()
    return instance ? instance.getState() : 'popularity'
  }

  getState () { return this.state.selectedId }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    view.on('click', 'li', (event, target) => {
      if (this.state.selectedId !== target.dataset.optionId && target.dataset.optionId !== undefined) {
        this.state.selectedId = target.dataset.optionId
        this.refresh()
        this._dispatcher.send('hotels_sort_order_updated', this.state.selectedId, 'sorting_component')
        URLFragment.update({ s: [this.state.selectedId] })
      }
      metrics.reach_goal('SERP_SORTING', {'sorting': this.getState()})
      this.hideModalPromise = setTimeout(() => { this.hideModal() }, 100)
    })
  }
}
