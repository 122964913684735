import _forEach from 'lodash/forEach'
import _extend from 'lodash/extend'
import Params from 'hotels/components/params_parser/search_params.js'
import dictionary from 'shared/lib/dictionary.js'
import paramsPresenter from 'hotels/components/params_parser/params_presenter'
import filters from 'shared/lib/filters.coffee'

export default class Proposal {
  constructor (params, hotel) {
    this.hotel = hotel
    _extend(this, params)
  }

  get gate_deeplink () {
    if (!this._cached_gate_deeplink) {
      let params = Params.params

      let linkObj = {
        deeplink: this.deeplink,
        gate_name: this.gate_name,
        price: filters.split_price(this.price),
        currency: params.currency,
        hotel_name: this.hotel.name,
        hotelId: this.hotel.id,
        destination: params.destination,
        checkIn: params.check_in,
        checkOut: params.check_out,
        adults: params.rooms[0].adults,
        locale: params.locale,
        children: params.rooms[0].children.join(','),
        booking_period: paramsPresenter.booking_period_text
      }

      if (linkObj.destination === null || linkObj.destination === this.hotel.name) {
        let locationId = (params.locations_ids && params.locations_ids[0]) || this.hotel.location_id
        linkObj.destination = dictionary.getName('locations', locationId) || ''
      }

      this._cached_gate_deeplink = Object.keys(linkObj).reduce((acc, key) => (acc += `${key}=${encodeURIComponent(linkObj[key])}&`), '/hotels/click.html?')
    }

    return this._cached_gate_deeplink
  }

  get gate_name () {
    if (!this._cached_gate_name) {
      this._cached_gate_name = dictionary.getName('gates', this.gate_id)
    }

    return this._cached_gate_name
  }

  get options () {
    if (!this._cached_options) {
      this._generateOptions()
    }

    return this._cached_options
  }

  get special_options () {
    if (!this._cached_special_options) {
      this._generateOptions()
    }

    return this._cached_special_options
  }

  discountChanged (discount) {
    this.discount = discount
  }

  _generateOptions () {
    let options = []
    let specialOptions = []

    _forEach(this.set_options, (val, key) => {
      if (!val) return
      if (key === '10') this.hotel_website = dictionary.getRow('proposals_options', key)
      if (key === '6' || key === '28') specialOptions.push({name: dictionary.getRow('proposals_options', key), id: key})
      options.push({name: dictionary.getRow('proposals_options', key), id: key})
    })

    this._cached_options = options
    if (specialOptions.length) this._cached_special_options = specialOptions
  }
}
