import Locale from 'shared/lib/locale'
import xhr from 'xhr'

export default function (hotelId, callback, options = {}) {
  let params = [
    'locale=' + encodeURIComponent(Locale.fixLocale(LOCALE)),
    'fallback_locale=' + encodeURIComponent(__('auto_generated.locales_fallback')[LOCALE]),
    'id=' + encodeURIComponent(hotelId),
    'limit=' + encodeURIComponent(options.limit || 5),
    'offset=' + encodeURIComponent(options.offset || 0),
    'gates=' + encodeURIComponent(options.gates || ''),
    'host=' + encodeURIComponent(options.host || window.location.hostname)
  ].join('&')

  xhr({
    uri: '/api/review/hotel?' + params,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    }
  }, (err, resp, body) => {
    if (!resp || resp.statusCode !== 200) console.error('Error', err, resp)

    try {
      body = JSON.parse(body)
    } catch (e) {
      body = false
      console.error('Error', e, resp)
    }

    callback(body)
  })
}
