import _extend from 'lodash/extend'
import _debounce from 'lodash/debounce'
import Device from 'shared/lib/device.js'
import Hotel from 'hotels/lib/models/hotel'
import Params from 'hotels/components/params_parser/search_params.js'
import Proposal from 'hotels/lib/models/proposal'
import colorUtils from 'shared/lib/color_utils.js'
import dictionary from 'shared/lib/dictionary.js'
import listenManager from 'shared/lib/listen_manager'
import paramsPresenter from 'hotels/components/params_parser/params_presenter.js'
import requestProcessor from 'hotels/lib/request_processor.js'

const whiteColor = '%23fff'
const greyColor = '%23999'
const buttonColor = `%23${window.TPWLCONFIG.color_scheme.btn_bg.slice(1)}`
const buttonContrastColor = `%23${colorUtils.shadeBlend(0.3, window.TPWLCONFIG.color_scheme.btn_bg, '#000000').slice(1)}`
const buttonTextColor = `%23${window.TPWLCONFIG.color_scheme.btn_text.slice(1)}`

const isTouch = Device.isTouch()
const touchSizemultiplier = isTouch ? 1.5 : 1
const iconSize = [12 * touchSizemultiplier, 12 * touchSizemultiplier]
const iconTouchSize = [20 * touchSizemultiplier, 20 * touchSizemultiplier]
const iconPoint = [6, 6]
const iconTouchPoint = [10, 10]

const ICONS = {
  without_price: {
    url: `data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22${12 * touchSizemultiplier}%22%20height%3D%22${12 * touchSizemultiplier}%22%20viewBox%3D%220%200%20${12 * touchSizemultiplier}%20${12 * touchSizemultiplier}%22%3E%3Ccircle%20cy%3D%22${(12 * touchSizemultiplier) / 2}%22%20cx%3D%22${(12 * touchSizemultiplier) / 2}%22%20r%3D%22${(12 * touchSizemultiplier) / 2}%22%20stroke%3D%22${whiteColor}%22%20fill%3D%22${greyColor}%22%20%2F%3E%3C%2Fsvg%3E`,
    size: iconSize,
    point: iconPoint
  },
  with_price: {
    url: `data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22${12 * touchSizemultiplier}%22%20height%3D%22${12 * touchSizemultiplier}%22%20viewBox%3D%220%200%20${12 * touchSizemultiplier}%20${12 * touchSizemultiplier}%22%3E%3Ccircle%20cy%3D%22${(12 * touchSizemultiplier) / 2}%22%20cx%3D%22${(12 * touchSizemultiplier) / 2}%22%20r%3D%22${(12 * touchSizemultiplier) / 2}%22%20stroke%3D%22${buttonTextColor}%22%20fill%3D%22${buttonColor}%22%20%2F%3E%3C%2Fsvg%3E`,
    size: iconSize,
    point: iconPoint
  },
  forced: {
    url: isTouch ? `data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22${12 * touchSizemultiplier}%22%20height%3D%22${12 * touchSizemultiplier}%22%20viewBox%3D%220%200%20${12 * touchSizemultiplier}%20${12 * touchSizemultiplier}%22%3E%3Ccircle%20cy%3D%22${(12 * touchSizemultiplier) / 2}%22%20cx%3D%22${(12 * touchSizemultiplier) / 2}%22%20r%3D%22${(12 * touchSizemultiplier) / 2}%22%20stroke%3D%22${buttonTextColor}%22%20fill%3D%22${buttonContrastColor}%22%20%2F%3E%3C%2Fsvg%3E` : `data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%0A%3Ccircle%20cy%3D%2210%22%20cx%3D%2210%22%20r%3D%225%22%20stroke%3D%22${buttonTextColor}%22%20fill%3D%22${buttonContrastColor}%22%20stroke-width%3D%222%22%2F%3E%3Ccircle%20cy%3D%2210%22%20cx%3D%2210%22%20r%3D%227%22%20stroke%3D%22${buttonContrastColor}%22%20stroke-width%3D%222%22%20fill%3D%22transparent%22%2F%3E%3C%2Fsvg%3E`,
    size: isTouch ? iconSize : iconTouchSize,
    point: isTouch ? iconPoint : iconTouchPoint
  }
}

const TOOLTIP_SIZES = {
  width: 350,
  height: 60,
  offsetX: 10,
  offsetY: 5
}

// Global debounce on info loading for all hotels
var loadInfoDebounced = _debounce((self) => { self.loadInfo.apply(self) }, 150)

let mouseMoveTracker = listenManager(window, 'mousemove', function (event) {
  mouseMoveTracker.event = event
})
if (!isTouch) mouseMoveTracker.start()

export default class {
  constructor (params, id, map) {
    _extend(this, {
      id, map,
      info: false,
      infoRequested: false,
      tooltip: { x: 0, y: 0},
      feature: {
        "type": "Feature",
        "properties": { hotel_id: id },
        "geometry": { type: "Point" }
      }
    }, params);
    this.feature.geometry.coordinates = [this.position.lng, this.position.lat]
    this.shown = false;
    this.active = false;
    this.forced = false;
    this.actual = false;
    // this._createMarker()
  }

  get icon () {
    let icon = ICONS[this.forced ? 'forced' : (this.has_price ? 'with_price' : 'without_price')]
    return {url: icon.url, size: window.google.maps.Size(...icon.size), scaledSize: new window.google.maps.Size(...icon.size), anchor: new window.google.maps.Point(...icon.point)}
  }

  get position () {
    if (this.pos === undefined) this.pos = {lat: this.pin[0], lng: this.pin[1]}
    return this.pos
  }

  get mousePosition () {
    return {x: mouseMoveTracker.event.clientX, y: mouseMoveTracker.event.clientY}
  }

  get link () { return this.info.link }

  get shown () { return this.feature.properties.shown }
  set shown (val) { this.feature.properties.shown = val }

  get active () { return this.feature.properties.active }
  set active(val) {
    if (val || this.feature.properties.active) this.map.active = (val && this) || false;
    this.feature.properties.active = val;
  }

  get forced() { return this.feature.properties.forced }
  set forced(val) {
    if (val || this.feature.properties.forced) this.map.forced = (val && this) || false;
    this.feature.properties.forced = val;
  }

  get actual() { return this.feature.properties.actual }
  set actual (val) { this.feature.properties.actual = val }

  get has_price() { return this.feature.properties.has_price }
  set has_price (val) { this.feature.properties.has_price = val }


  activate () {
    if (this.active || this.forced) return false
    if (this.map.active) this.map.active.deactivate()
    if ((!this.info && !this.infoRequested) || (!this.actual && !this.infoRequested)) loadInfoDebounced(this)
    if (isTouch) return false
    this.showPromise = setTimeout(() => {
      this._showActive()
      this._refreshIfShown()
    }, 200)
    this.active = true
  }

  deactivate() {
    if (!this.active) return true
    if (!isTouch) mouseMoveTracker.start()
    if (this.showPromise) clearTimeout(this.showPromise)
    this.active = false
  }

  force () {
    if (this.forced) return false
    if (this.map.forced) this.map.forced.unforce()
    if (this.active) this.deactivate()
    if (isTouch) {
      this.map.map.easeTo({
        center: this.position,
        offset: [0, 153]
      })
    }
    if (!this.infoRequested) this.loadInfo()

    this.forced = true
  }

  unforce () {
    if (!this.forced) return false
    this.forced = false
  }

  _actualize () {
    this.actual = true
    this.map.actualHotelIds.push(this.id)
  }

  deactualize () {
    this.actual = false
    if (this.info.proposal) {
      this._refreshIfShown()
    }
  }

  update (params) {
    if (this.has_price !== params.has_price) {
      this.has_price = this.feature.properties.has_price = params.has_price
      return true;
    }
    return false;
  }

  _showActive () {
    this.showPromise = false
    if (isTouch) return false
    mouseMoveTracker.stop()
    this.tooltip = this._calculateTooltipPosition(this.mousePosition, this.map, TOOLTIP_SIZES)
  }

  _calculateTooltipPosition (mouse, map, tooltip) {
    let rightMap = 0
    let leftMap = 0
    let position = {}

    if (Device.isDesktopSize()) {
      map.hasFilters = !Params.params.hotels_ids.length
      let filtersOffset = map.hasFilters ? map.view.createDocument().parentNode.offsetWidth + 40 : 0
      let offset = filtersOffset - window.pageXOffset

      Device.isRtl() ? rightMap = offset : leftMap = offset
    }

    mouse = {x: mouse.x - leftMap - 10, y: mouse.y - 10}

    position.left = mouse.x - tooltip.offsetX
    position.top = mouse.y - tooltip.offsetY - tooltip.height

    if (position.left < 0) position.left = 5
    if (position.left + tooltip.width + rightMap > window.innerWidth - leftMap) {
      position.left = false
      position.right = 5
    }
    if (position.top < 0) position.top = mouse.y + tooltip.offsetY + 20
    return position
  }

  _refreshIfShown () {
    if ((this.map.active && this.id === this.map.active.id) || (this.map.forced && this.id === this.map.forced.id)) {
      this.map.refresh()
    }
  }

  loadInfo () {
    this.infoRequested = true
    if (this.map.requestForMapOnHotelPage) {
      this.info.galleryForce = true
      if (this.info.proposal) {
        this.has_price = true
      } else {
        this.has_price = false
      }
      return false
    }
    if (this.requestProcessor === undefined) this.requestProcessor = requestProcessor()

    this.requestProcessor.mapHotelInfoRequest('map_marker', this.id, (requestParams, body, source, err) => {
      if (body.hotels && body.hotels[0]) {
        this.info = new Hotel(body.hotels[0])
        if (body.proposals[this.id]) {
          let proposal = new Proposal(body.proposals[this.id].proposals[0])
          this.info.proposalsUpdated([proposal])
          this.info.galleryForce = true
        }
        this._actualize()
      }
      this._refreshIfShown()
    })
  }
}
