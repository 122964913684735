moment = require('moment')
Monkberry = require('monkberry')
require('monkberry-events')
dispatcher = require('shared/lib/dispatcher')
metrics = require('shared/lib/metrics.coffee')
Template = require('shared/components/system_messages/system_messages.monk')

preroll_type_classes =
  error_page: 'preroll-container--error'

error_types =
  wrong_dates:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__wrong_dates"
    message: __('errors.wrong_dates.message')
    description:
      text: __('errors.wrong_dates.description')
  bad_search_params:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__bad_search_params"
    message: __('errors.bad_search_params.message')
    description:
      text: __('errors.bad_search_params.description')
    error_type: 'bad_search_params'
  check_out_days:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__check_out_days"
    message: __('errors.check_out_days.message')
    description:
      text: __('errors.check_out_days.description')
    error_type: 'check_out_days'
  search_failed:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__search_failed"
    message: __('errors.search_failed.message')
    description:
      text: __('errors.search_failed.description')
  empty_tickets:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__empty_tickets"
    message: __('errors.empty_tickets.message')
    description:
      text: __('errors.empty_tickets.description')
    error_type: 'empty_tickets'
  filtered_all_hotels:
    message_type: "#{preroll_type_classes.error_page}__filtered_all_hotels"
    message: ""
    description:
      text: __('errors.filtered_all_hotels.description')
      additional_description: __('errors.filtered_all_hotels.additional_description')
    error_type: 'filtered_all_hotels'

message = (container_el)->
  messages_obj = Monkberry.render(Template, container_el)
  messages_obj.update {
    message_type: ''
    message: ''
  }

  tickets_received = false
  hotels_received = false

  set_error = (params)->
    messages_obj.update({
      message_type: params.message_type
      message: params.message
      description:
        text: params.description.text
        additional_description: params.description.additional_description
      error_type: params.error_type
    })

  showMessage = -> container_el.classList.remove('hidden')

  dispatcher.on('start_search', (event, params) ->
    container_el.classList.add('hidden')
    tickets_received = false
    hotels_received = false
  )

  dispatcher.on('search_failed', (event, {request_id, data, params}) ->
    if data?.status == 400
      date_diff_by_days = moment(params.check_out, "YYYY-MM-DD").diff(moment(params.check_in,"YYYY-MM-DD"), 'days')

      error = if date_diff_by_days > 30 then error_types.check_out_days else error_types.bad_search_params
    else
      error = error_types.search_failed

    set_error(error)
    dispatcher.send('systemMessageError')
    showMessage()
    document.body.classList.add("TPWL-widget--system_error")
  )

  dispatcher.on('serp_hotels_changed', (event, {request_id, hotels})->
    container_el.classList.add('hidden')
    hotels_received = true
    if hotels_received and hotels.length == 0
      set_error(error_types.filtered_all_hotels)
      showMessage()
  )

module.exports = message
