{% import List from './list/list.monk' %}

<section class="hotel_page-advantages">
  <div class="hotel_page-advantages-col">
    <div class="hotel_page-advantages-info">
      <h4 class="hotel_page-advantages-info_title">{{ __('hotel_page.advantages.hotel.advantages') }}</h4>
    </div>
  </div>
  <div class="hotel_page-advantages-col">
    <div class="hotel_page-advantages-info">
      <h4 class="hotel_page-advantages-info_title">{{ __('hotel_page.advantages.hotel.about') }}</h4>
      <ul class="hotel_page-advantages-list">
        {% if(hotel.check_in && hotel.check_out) %}
          <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--check_time">
            {{ __('hotel_page.advantages.hotel.check_in') }}&nbsp;<strong>{{ hotel.check_in }}</strong>
            &nbsp;
            {{ __('hotel_page.advantages.hotel.check_out') }}&nbsp;<strong>{{ hotel.check_out }}</strong>
          </li>
        {% endif %}
        {% if(hotel.room_count ) %}
          <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--hotel_rooms">
            {{ __('hotel_page.advantages.hotel.hotel') }}&nbsp;
            {{ hotel.room_count }}&nbsp;
            {{ __('hotel_page.advantages.hotel.rooms') }}
          </li>
        {% endif %}
        {% if(hotel.year_opened ) %}
          <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--hotel_open">
            {{ __('hotel_page.advantages.hotel.build_in') }}&nbsp;
            {{ hotel.year_opened }}&nbsp;
            <span class="hotel_page-advtages-list_item--hotel_open_hide_year"> {{ __('hotel_page.advantages.hotel.year') }} </span>
          </li>
        {% endif %}
        {% if(hotel.year_renovated ) %}
          <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--hotel_renovated">
            {{ __('hotel_page.advantages.hotel.renovated') }}:&nbsp;
            {{ hotel.year_renovated }}&nbsp;
            {{ __('hotel_page.advantages.hotel.year') }}
          </li>
        {% endif %}
        {% if hotel.amenitiesv2 && hotel.amenitiesv2.categories && hotel.amenitiesv2.categories.language %}
            <li class="hotel_page-advtages-list_item hotel_page-advtages-list_item--hotel_language">
              {{ __('hotel_page.advantages.hotel.staff_speak_on') }}:&nbsp;
              {{ hotel.amenitiesv2.categories.language.values[0].name }}
              {% for language of hotel.amenitiesv2.categories.language.values.slice(1) %},&nbsp;{{ language.name }}
              {% endfor %}
              &nbsp;{{ __('hotel_page.advantages.hotel.language') }}
            </li>
        {% endif %}
      </ul>
    </div>
    {% if hotel.amenitiesv2 && hotel.amenitiesv2.categories && hotel.amenitiesv2.categories.hotel %}
      <List title="{{ __('hotel_page.advantages.hotel_facilities') }}"
               size="{{ hotel.amenitiesv2.categories.hotel.values.length }}"
               list="{{ hotel.amenitiesv2.categories.hotel.values }}"/>
    {% endif %}
  </div>
  {% if hotel.amenitiesv2 && hotel.amenitiesv2.categories && hotel.amenitiesv2.categories.room %}
    <div class="hotel_page-advantages-col">
      <List title="{{ __('hotel_page.advantages.room_facilities') }}"
               size="{{ hotel.amenitiesv2.categories.room.values.length }}"
               list="{{ hotel.amenitiesv2.categories.room.values }}"/>
    </div>
  {% endif %}
</section>
