<section class="hotels-gallery{{ preloadingFirst ? ' hotels-gallery--preloading' : ''}}" style="width: {{width}}px; height: {{height}}px;" role="gallery_wrapper">
  <div class="hotels-gallery__full_screen" role="gallery-full-screen-toggler"></div>
  <ul class="hotels-gallery_list" role="hotels_gallery">
    {% for photoURL of selectedURLs %}
      <li class="hotels-gallery_list-item" style="background-image: url('{{ photoURL }}'); height: {{height}}px; background-size: {{width}}px {{height}}px;" role="gallery-list-item"></li>
    {% endfor %}
  </ul>

  <div class="hotels-gallery-controls" >
    <div class="hotels-gallery__control hotels-gallery__control--prev" role="hotels_gallery_prev"></div>
    <div class="hotels-gallery__control hotels-gallery__control--next" role="hotels_gallery_next"></div>
  </div>

  <div class="hotels-gallery__counter">
    {{ (currentItem + 1) || 1 }}&nbsp;{{ __('gallery.from') }}&nbsp;{{ len }}
  </div>
</section>
