import delegate from 'dom-delegate'
import dispatcher from 'shared/lib/dispatcher'

export default class FiltersWrapper {
  constructor (node, options = {}) {
    this.view = node
    this._initEvents()
  }

  _initEvents () {
    let eventDelegate = delegate(this.view)
    eventDelegate.on('click', '[role="close_filters"]', () => {
      dispatcher.send('filters_popup_closed')
    })
  }
}
