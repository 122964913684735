import _sortBy from 'lodash/sortBy'
import _extend from 'lodash/extend'
import Device from 'shared/lib/device.js'
import Map from 'hotels/components/map/map.js'
import Params from 'hotels/components/params_parser/search_params.js'
import dictionary from 'shared/lib/dictionary.js'
import photoUtils from 'shared/lib/photo_utils.js'

const TRASH_TYPE_ID = 0
export default class Hotel {
  constructor(params, searchId) {
    this.proposals = []
    this.proposal = false
    this.isSelected = false
    this.searchId = searchId
    _extend(this, params)
  }

  get type() {
    if (!this._cached_type) {
      this._cached_type = dictionary.property_types[this.property_type_id] || this.property_type
    }

    return this._cached_type
  }

  get district() {
    if (!this._cached_district) {
      this._cached_district = dictionary.getName('districts', this.districts_ids[0])
    }

    return this._cached_district
  }

  get trustyou() {
    if (!this._cached_trustyou) {
      this._cached_trustyou = dictionary.getName('trustyou', this.districts_ids[0])
    }

    return this._cached_trustyou
  }

get link() {
  if (!this._cached_link) {
    let name = encodeURIComponent(this.name);
    let url = window.location.pathname + window.location.search + (window.location.search === '' ? '?' : '&');
    let locationId = (Params.params.locations_ids && Params.params.locations_ids[0]) || this.location_id;

    if (window.location.search.indexOf('locationId=') === -1) {
      url += `locationId=${locationId}&`;
    }

    if (this.id) {
      if (window.location.search.indexOf('hotelId=') === -1) {
        url += `hotelId=${this.id}&`;
      } else {
        url = url.replace(/A?hotelId=[^&]*&*/, `hotelId=${this.id}&`);
      }
    }

    if (window.location.search.indexOf('searchId=') === -1 && this.searchId) {
      url += `searchId=${this.searchId}&`;
    }

    url = url.endsWith('&') ? url.slice(0, -1) : url;

    this._cached_link = `${url}&hotelName=${name}`;
  }

  return this._cached_link;
}

  get main_images() {
    if (!this._cached_main_images) {
      var mapWidth, mapHeight, zoom
      [mapWidth, mapHeight, zoom] = this.photos_count === 0 ? [620, 400, 13] : (this.photos_count === 1 ? [310, 400, 12] : [310, 195, 13])
      // Hack because we use free google map plan https://developers.google.com/maps/documentation/static-maps/intro#Imagesizes
      let mapUrlWidth = Device.pixelsWithRatio(mapWidth)
      const mapUrlHeight = mapUrlWidth > 640 ? 390 : Device.pixelsWithRatio(mapHeight)
      if (mapUrlWidth > 640) { mapUrlWidth = 620 }

      this._cached_main_images = {
        main_url: this.photos_ids[0] ? `https://photo.hotellook.com/image_v2/crop/${this.photos_ids[0]}/${Device.pixelsWithRatio(620)}/${Device.pixelsWithRatio(400)}.auto` : false,
        galery_url: this.photos_ids[1] ? `https://photo.hotellook.com/image_v2/crop/${this.photos_ids[1]}/${Device.pixelsWithRatio(310)}/${Device.pixelsWithRatio(195)}.auto` : false,
        map_url: `https://api.maptiler.com/maps/bright/static/${this.location.lon},${this.location.lat},${zoom}/${mapUrlWidth}x${mapUrlHeight}@2x.png?key=${Map.API_KEY}`,
        map_preview_url: `https://api.maptiler.com/maps/bright/static/${this.location.lon},${this.location.lat},${zoom}/${Device.pixelsWithRatio(200)}x${Device.pixelsWithRatio(100)}@2x.png?key=${Map.API_KEY}&markers=${this.location.lon},${this.location.lat}`
      }
    }

    return this._cached_main_images
  }

  get photo() {
    if (!this._cached_photo) {
      this._cached_photo = this.photoUrl(0, 297, 175)
    }

    return this._cached_photo
  }

  get best_group() {
    if (!this._cached_best_group && this.proposal) {
      this._groupProposals()
    }

    return this._cached_best_group || {}
  }

  get grouped_proposals() {
    if (!this._cached_grouped_proposals && this.proposal) {
      this._groupProposals()
    }

    return this._cached_grouped_proposals || []
  }

  photoUrl(index, width, height) {
    return this.photos_ids && this.photos_ids[index] && photoUtils._getPhotoURLById(this.photos_ids[index], width, height)
  }

  proposalsUpdated(proposals) {
    this.proposals = proposals
    this.proposal = this.proposals[0]
    delete this._cached_best_group
    delete this._cached_grouped_proposals
  }

  _groupProposals() {
    let proposals = _sortBy(this.proposals, ['price', 'discount.change_percentage'])
    let result = []
    let temporary = {}
    let bestGroup = this._getGroupParams(proposals[0])
    bestGroup.items = [proposals[0]]

    // REFACTOR ME
    proposals.forEach((proposal) => {
      if (bestGroup.proposal.discount === undefined &&
        bestGroup.proposal.price === proposal.price &&
        bestGroup.proposal.internal_type_id === TRASH_TYPE_ID &&
        proposal.internal_type_id !== TRASH_TYPE_ID) {
        bestGroup = this._getGroupParams(proposal)
      }

      if (temporary[proposal.internal_type_id] === undefined) {
        temporary[proposal.internal_type_id] = this._getGroupParams(proposal)

        if (proposal.internal_type_id !== TRASH_TYPE_ID) result.push(temporary[proposal.internal_type_id])
      }
      temporary[proposal.internal_type_id].items.push(proposal)
    })
    if (temporary[TRASH_TYPE_ID] !== undefined) result.push(temporary[TRASH_TYPE_ID])

    this._cached_best_group = bestGroup
    this._cached_grouped_proposals = result
  }

  _getGroupParams(proposal) {
    let roomPhotos = proposal.hotel.photos_by_room_type[proposal.internal_type_id]
    let roomPhotoId = (roomPhotos === undefined ? false : roomPhotos.photos_ids[0])
    let photoCount = (roomPhotos === undefined ? false : roomPhotos.photos_ids.length)
    let roomPhotoURL = roomPhotoId ? photoUtils._getPhotoURLById(roomPhotoId, 160, 120) : false
    return {
      room_id: proposal.internal_type_id,
      photo_count: photoCount,
      photo_id: roomPhotoId,
      photo_url: roomPhotoURL,
      proposal: proposal,
      items: []
    }
  }
}
