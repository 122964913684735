{% import HotelHeader from './header/header.monk' %}
{% import Photos from './photos/photos.monk' %}
{% import AvailableRooms from './available_rooms/available_rooms.monk' %}
{% import Proposals from './proposals/proposals.monk' %}
{% import MobileLocation from './mobile_location/mobile_location.monk' %}
{% import Rating from './rating/rating.js' %}
{% import Reviews from './reviews/reviews.monk' %}
{% import Advantages from './advantages/advantages.js' %}
{% import Description from './description/description.monk' %}
{% import SimilarHotels from './similar_hotels/similar_hotels.monk' %}
{% import paramsPresenter from 'hotels/components/params_parser/params_presenter' %}

<div class="hotel_page-container">
  <div class="search_progressbar-container" data-title="{{ __('search_progressbar.hotel_title') }}" is="search_progressbar"></div>
  <div class="expired-search-plate hidden" is="expired_search"></div>
  <div class="system-messages hidden" is="system_messages"></div>
  <div class="search_results-map" is="map"></div>
  <section class="hotel_page{{ is_loading ? ' hotel_page--loading' : '' }}{{ searchFinished ? '' : ' hotel_page--search_in_progress' }}">
    <header class="hotel_page-search_details">
      <div class="hotel_page-search_details--place">
        {{ hotel.location_name }}
      </div>
      <div class="hotel_page-search_details--additional_info">
        {{ paramsPresenter.currentDatesFormatted() }}, {{ paramsPresenter.guests }}
      </div>
    </header>
    <HotelHeader location="{{ location }}" location_link="{{ location_link }}" hotel="{{ hotel }}" />
    <Photos hotel="{{ hotel }}" currency="{{ currency }}"/>
    <AvailableRooms hotel="{{ hotel }}" />
    <div is="hotel_proposals"></div>
    <MobileLocation hotel="{{ hotel }}" />
    <Rating hotel="{{ hotel }}" />
    <Advantages hotel="{{ hotel }}"/>
    <div is="hotel_reviews"></div>
    <Description hotel="{{ hotel }}" />
    <SimilarHotels similarHotels="{{ similarHotels }}" location="{{ location }}" currency="{{ currency }}" />
    <div class="hotel_page-footer-search">
      <div class="hotel_page-footer_search_text"> {{ __('hotel_page.footer.search_text') }} </div>
      <a href="{{ location_link }}" class="hotel_page-footer_search_link">
        {{ __('hotel_page.footer.search_link') }}
      </a>
    </div>
    <div class="hotel_page-scroll_top_button" is="scroll_top_button"></div>
    <div class="hotel_page-photoswipe" is="photoswipe"></div>
  </section>
</div>
