<section class="hotel_page-rating {{  hotel.rating > 0 ? '' : 'hotel_page-rating--hidden' }}">
  {% if hotel.rating > 0 %}
    <header class="hotel_page-rating__header">
      <h4>{{ __('hotel_page.rating.header') }}</h4>
    </header>

    <div class="hotel_page-rating_pie-wrapper">
      <div class="hotel_page-rating_pie" style="animation-delay: -{{ hotel.rating * 10 }}s;">
        <div class="hotel_page-rating_pie__text">
          <div class="hotel_page-pie__average">{{ __('hotel_page.rating.average') }}</div>
          <div class="hotel_page-pie__rating">{{ hotel.rating }}</div>
          <div class="hotel_page-pie__additional"><span>{{ __('hotel_page.rating.based_on') }} {{ hotel.reviews_count | pluralize(__('hotel_page.reviews')) }}</span></div>
        </div>
      </div>
    </div>

    <div class="hotel_page-rating_mobile">
      <div class="hotel_page-rating_mobile__total-score">
        <span>{{ hotel.rating.toFixed(1) }}</span>
      </div>
      <div class="hotel_page-rating_mobile__info">
        <span>{{ __('hotel_page.reviews_mark')}}</span>
        <span>{{ __('hotel_page.rating.based_on') }}&nbsp;{{ hotel.reviews_count | pluralize(__('hotel_page.reviews')) }}</span>
      </div>
    </div>

    {% if hotel.ratings && hotel.ratings.sentiment_score_list %}
      <div class="hotel_page-rating_bars">
        <ul class="hotel_page-rating_bars-list">
          {% for rating of hotel.ratings.sentiment_score_list %}
            {% if(rating.category_name) %}
              <li class="hotel_page-rating_bars-list-el">
                <dl class="hotel_page-rating_bars-bar">
                  <dt>{{ rating.category_name }}</dt>
                  <dd>
                    <span class="hotel_page-rating_bars__percentage" style="width: {{ rating.score * 10 }}%">{{ rating.score.toFixed(1) }}</span>

                    <span class="hotel_page-rating_bars__circle">{{ rating.score.toFixed(1) }}</span>
                  </dd>
                </dl>
              </li>
            {% endif %}
          {% endfor %}
        </ul>
      </div>
    {% endif %}
  {% endif %}
</section>
