_reduce = require('lodash/reduce')
_map = require('lodash/map')
_includes = require('lodash/includes')
_map = require('lodash/map')
moment = require('moment')
queryString = require('shared/lib/query_string.js')

params_map = {
  '^(children)([^_]|$)': (p, matches, value) ->
    p["rooms"] ||= [{"children": []}]
    room = p["rooms"][0]
    for children in value.split(",")
      room[matches[1]].push(parseInt(children))
    p

  '^(currency)$': (p, matches, value) ->
    p["currency"] = value.toLowerCase() if(value && /^\w{3}$/.test(value))
    p

  '^adults': (p, matches, value) ->
    p["rooms"] ||= [{"children": []}]
    p["rooms"][0][matches[0]] = parseInt(value)
    p

  '^destination|marker': (p, matches, value) ->
    p[matches[0]] = value + ''
    p

  '^locationId|cityId|location_id|locations_ids': (p, matches, value) ->
    p["locations_ids"] = if !!parseInt(value) then value else null
    p

  '^hotelId|hotels_ids': (p, matches, value) ->
    p["hotels_ids"] = value
    p

  '^searchId': (p, matches, value) ->
    p["search_id"] = value
    p

  '^hotelName': (p, matches, value) ->
    p["hotel_name"] = value
    p

  '^geo\\\[(fullName|lat|lon|name)\\\]': (p, matches, value) ->
    p["geo"] ||= {}
    p["geo"][matches[1]] = value
    p

  '^checkIn|checkin|check_in$': (p, matches, value) ->
    [_, y, m, d] = value.match(/(\d*)-(\d*)-(\d*)/)
    m = "0" + m if m.length == 1
    d = "0" + d if d.length == 1
    p["check_in"] = [y, m, d].join('-')
    p

  '^checkOut|checkout|check_out$': (p, matches, value) ->
    [_, y, m, d] = value.match(/(\d*)-(\d*)-(\d*)/)
    m = "0" + m if m.length == 1
    d = "0" + d if d.length == 1
    p["check_out"] = [y, m, d].join('-')
    p

  '^utm_.*$': (p, matches, value) ->
    p["flags"] ||= {}
    p["flags"]["utmDetail"] ||= []
    p["flags"]["utmDetail"].push("#{matches[0]}=#{value}")
    p
}

module.exports = (location) ->
  params = queryString.parse(location)
  parsed_params = {}

  # Это штука для превьюшки отельной? Мы ее как-то будем переделывать?
  parsed_params.host = params.host if params.host
  parsed_params.preview = params.preview if params.preview

  if params['destination']
    parsed_params["locations_ids"] = null
    parsed_params["hotels_ids"] = null


  for param_name, value of params
    for regex, callback of params_map
      reg = new RegExp(regex, 'g')
      matches = reg.exec(param_name)
      if matches? && value
        callback(parsed_params, matches, value)
        break

  if parsed_params["check_in"] && moment().add(-2, 'days') > moment(parsed_params["check_in"], 'YYYY-MM-DD')
    parsed_params["check_in"] = moment().locale('en').add(1, 'days').format('YYYY-MM-DD')

  if parsed_params["check_out"] && moment(parsed_params["check_in"], 'YYYY-MM-DD') > moment(parsed_params["check_out"], 'YYYY-MM-DD')
    parsed_params["check_out"] = moment(parsed_params["check_in"], 'YYYY-MM-DD').locale('en').add(1, 'days').format('YYYY-MM-DD')

  parsed_params["destination"] = null if parsed_params["locations_ids"] && !parsed_params["destination"]

  if parsed_params["hotels_ids"] && !parsed_params["locations_ids"]
    parsed_params["locations_ids"] = null
    parsed_params["destination"] = null if !parsed_params["destination"]

  if parsed_params["geo"]
    parsed_params["locations_ids" ] = null
    parsed_params["destination"] = parsed_params["geo"]["name"]

  if parsed_params["flags"] && parsed_params["flags"]["utmDetail"] && parsed_params["flags"]["utmDetail"].length
    parsed_params["flags"]["utmDetail"] = parsed_params["flags"]["utmDetail"].join('&')

  parsed_params["locale"] = LOCALE

  parsed_params
