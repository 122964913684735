{% import Device from 'shared/lib/device.js' %}

<li class="hotel_page-reviews-list-item" id="{{id}}">
    <div class="hotel_page-reviews-list-item-author">
      <div class="hotel_page-reviews-list-item-author__name">{{ review.author_name || __('hotel_page.reviews_comments.guest') }}</div>
      <div class="hotel_page-reviews-list-item-author__message_time">
        {{ review.created_at | dateString }}
      </div>
      <div class="hotel_page-reviews-list-item-rating-wrapper">
        {% if (review.rating) %}
          <div class="hotel_page-reviews-list-item-rating">{{ review.rating.val }}</div>
        {% endif %}
        <a href="{{ review.url }}" target="_blank" class="hotel_page-reviews-list-item-rating-gate {{review.url ? '' : 'hotel_page-reviews-list-item-rating-gate--no-click'}}">
          <img src="https://pics.avs.io/hl_gates/{{Device.pixelsWithRatio(90)}}/{{Device.pixelsWithRatio(24)}}/{{review.gate_id}}--east@2x.png" class="hotel_page-reviews-list-item-rating-gate__icon"/>
        </a>
      </div>
    </div>
    <div class="hotel_page-reviews-list-item-review-wrapper">
      {% for key of textKeys %}
        {% if review[key].length %}
          <div class="hotel_page-reviews-list-item-review">
            <div class="hotel_page-reviews-list-review__text hotel_page-reviews-list-review__text--{{key}}">
              {{ review[key] }}
            </div>
          </div>
        {% endif %}
      {% endfor %}
    </div>
  </li>
