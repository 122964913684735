import _debounce from 'lodash/debounce'
import DOMComponent from 'shared/components/base.js'
import Template from './scroll_top_button.monk'

export default class ScrollTopButton extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'scroll_top_button',
      render: {
        template: Template
      },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.scroll_top_button--icon': {
            'background': "url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2215px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2015%2024%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20id%3D%22Welcome%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Tablet-Hotel%22%20transform%3D%22translate(-686.000000%2C%20-5214.000000)%22%20fill%3D%22" + encodeURIComponent(window.TPWLCONFIG.color_scheme.text_interesting) + "%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22%E2%86%91%22%20transform%3D%22translate(668.000000%2C%205200.000000)%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M26.1066667%2C18.2758621%20L31.74%2C24.2528736%20C32.0866667%2C24.6206897%2032.0866667%2C25.1724138%2031.74%2C25.5402299%20C31.3933333%2C25.908046%2030.8733333%2C25.908046%2030.5266667%2C25.5402299%20L26.3666667%2C21.1264368%20L26.3666667%2C37.0804598%20C26.3666667%2C37.5862069%2025.9766667%2C38%2025.5%2C38%20C25.0233333%2C38%2024.6333333%2C37.5862069%2024.6333333%2C37.0804598%20L24.6333333%2C21.1264368%20L20.4733333%2C25.5402299%20C20.1266667%2C25.908046%2019.6066667%2C25.908046%2019.26%2C25.5402299%20C18.9133333%2C25.1724138%2018.9133333%2C24.6206897%2019.26%2C24.2528736%20L24.8933333%2C18.2758621%20C25.24%2C17.908046%2025.76%2C17.908046%2026.1066667%2C18.2758621%20Z%20M32.1176471%2C14%20C32.6029412%2C14%2033%2C14.45%2033%2C15%20C33%2C15.55%2032.6029412%2C16%2032.1176471%2C16%20L18.8823529%2C16%20C18.3970588%2C16%2018%2C15.55%2018%2C15%20C18%2C14.45%2018.3970588%2C14%2018.8823529%2C14%20L32.1176471%2C14%20Z%22%20id%3D%22Up%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E') 50% 50% no-repeat"
          }
        }
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
  }

  _initDOMEvents (view) {
    this.hide()
    view.update()

    view.on('click', '[role="scroll_top_button"]', () => {
      window.scrollTo(0, 0)
    })

    window.addEventListener('scroll', _debounce(() => {
      if (window.pageYOffset > window.innerHeight / 2) {
        this.show()
      } else {
        this.hide()
      }
    }, 100)
    )
  }
}
