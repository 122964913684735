{% import Device from 'shared/lib/device.js' %}

<div class="hotel_proposal">
  <div class="hotel_proposal-price">
    <a target="_blank" role="link_to" class="hotel_proposal-price-button_wrapper" href="{{ proposal.gate_deeplink }}source=hotel&sourceDetails=hotelProposal" metric="PAGE_PROPOSAL_CLICK">
      {% if( !proposal.hotel_website ) %}
        <span class="hotel_proposal-price__button">
          {{ __('hotel_page.book_now') }}
        </span>
      {% else %}
        <span class="hotel_proposal-price__button hotel_proposal-price__button--hotel_website">
          {{ __('hotel_page.hotel_website') }}
        </span>
      {% endif %}

      {% if(proposal.special_options && proposal.special_options[0]) %}
        <div class="hotel_proposal-special">
          {{ proposal.special_options[0].name }}
        </div>
      {% endif %}
    </a>
    <span class="hotel_proposal-price-button_wrapper hotel_proposal-price__button hotel_proposal-price-button_wrapper--expired" role="reload_button">
      {{ __('informer.update_results') }}
    </span>
    <div class="hotel_proposal-price_text">
      {% if proposal.discount %}
        <div class="hotel_proposal-price-discount">
          <span class="hotel_proposal-price-discount__percent">
            <span class="hotel_proposal-price-discount-percent__text">
              {{ proposal.discount.change_percentage }}&rlm;%
            </span>
          </span>
          <span class="hotel_proposal-price-discount__old_price">
            <span class="currency_font currency_font--{{ currency }}">
                {{ proposal.discount.old_price | split_price }}
            </span>
          </span>
        </div>
      {% endif %}
      <span class="currency_font currency_font--{{ currency }}">
        {{ proposal.price | split_price }}
      </span>
      <span class="hotel_proposal-price_text-vat">
        {{__('hotel_page.vat_tax_included')}}
      </span>
    </div>
  </div>
  <div class="hotel_proposal-info">
    <div class="hotel_proposal-info-room">
      {{ proposal.name }}
    </div>
    {% if proposal.options && proposal.options.length %}
      <ul class="hotel_proposal-info-options">
        {% for option of proposal.options.slice(0,4) %}
          <li class="hotel_proposal-info-options__item hotel_proposal-info-options__item--{{option.id}}">{{option.name}}</li>
        {% endfor %}
      </ul>
    {% endif %}
    <div class="hotel_proposal-info-gate_info">
      <div class="hotel_proposal-info-gate_info__guests"></div>
      <img src="https://pics.avs.io/hl_gates/{{Device.pixelsWithRatio(120)}}/{{Device.pixelsWithRatio(30)}}/{{proposal.gate_id}}--{{Device.isNarrowTabletMobileSize() ?  direction ? 'northeast' : 'northwest' : direction ? 'west' : 'east'}}@2x.png" class="hotel_proposal-info-gate_info__logo"/>
    </div>
    {% if bestGroup %}
      <a target="{{ targetLink }}" class="hotel_page-proposals-group-best_proposal__buy_button" href="{{ bestGroup.proposal.gate_deeplink }}" metric="PAGE_PROPOSAL_CLICK">
        {{__('hotel_page.book_now')}}
      </a>
      <span class="hotel_page-proposals-group-best_proposal__buy_button hotel_page-proposals-group-best_proposal__buy_button--expired" role="reload_button">
        {{ __('informer.update_results') }}
      </span>
      <span class="hotel_page-proposals-group-best_proposal__expand_link hotel_page-link-with-arrow" role="proposals_expander">
        {{__('hotel_page.all_rooms')}}
      </span>
    {% endif %}
  </div>
</div>
