import _forEach from 'lodash/forEach'
import _extend from 'lodash/extend'
import _debounce from 'lodash/debounce'
import Device from 'shared/lib/device'
import Filters from 'hotels/components/filters/filters.js'
import Locale from 'shared/lib/locale'
import Params from 'hotels/components/params_parser/search_params.js'
import URLFragment from 'shared/lib/url_fragment.js'
import Sorting from 'hotels/components/sorting/sorting.js'
import cookies from 'shared/lib/cookies.coffee'
import dispatcher from 'shared/lib/dispatcher'
import router from 'shared/lib/router.js'
import xhr from 'xhr'
import metrics from 'shared/lib/metrics.coffee'
import { request } from 'http';
var SERVICE_MARKER = ''

class RequestProcessor {
  constructor (options) {
    this.searchId = options.searchId === undefined ? '' : options.searchId
    this.chunkRequestPeriod = 1000
    this.limit = 10
    this.searchStarted = false
    this.searchFinished = false
    this.searchFailed = false
    this.requestsInfo = {
      'serp': {
        xhr: false,
        loader: false,
        limit: 10
      },
      'map': {
        xhr: false,
        loader: false,
        limit: 20000
      },
      'hotel_page': {
        xhr: false,
        loader: false,
        limit: 1
      }
    }
    this.errorAttempts = 0
    this.page = router.page
    this.eventKeys = [
      'gates', 'districts', 'hotels_amenities', 'proposals_options', 'locations',
      'property_types', 'room_types', 'trustyou'
    ]

    this.serpRequest = _debounce((source) => this._doRequest(source, 'serp'), 300)
    this.mapRequest = _debounce((source) => this._doRequest(source, 'map'), 500)
    this.defaultRequest = _debounce((source) => this._doRequest(source, this.page), 300)

    if (options.doRequest !== false) this.pageRequest('initial')
  }

  pageRequest (source, page = false) {
    (this[`${page || this.page}Request`] || this.defaultRequest)(source)
  }

  changePage (page) {
    let prevPage = this.page
    this.page = page
    if (prevPage !== null) this.pageRequest('change_page')
  }

  addServiceMarker (params) {
    params.marker = params.marker.replace(SERVICE_MARKER, '')
    params.marker += SERVICE_MARKER
    return params
  }

  requestParams (page = false) {
    Params.params.locale = Locale.fixLocale(Params.params.locale)
    Params.params.fallback_locale = __('auto_generated.locales_fallback')[LOCALE]
    const selected_hotels_ids = URLFragment.state.selected_hotels_ids && URLFragment.state.selected_hotels_ids.length ?
      URLFragment.state.selected_hotels_ids[0].split(',').map(el => parseInt(el, 10)) : []
    let params = {
      page: page || this.page,
      search_id: this.searchId,
      params: _extend({}, this.addServiceMarker(Params.params), {host: window.location.hostname}),
      selected_hotels_ids,
      filters: Filters.getState(),
      sort: Sorting.getState(),
      limit: (this.requestsInfo[page] && this.requestsInfo[page].limit) || this.requestParams['serp'].limit,
      offset: 0
    }
    params['params']['flags'] = _extend(params['params']['flags'] || {}, {
      'auid': cookies.get('wl_auid'),
      'ab': cookies.get('wl_ab'),
      'deviceType': Device.isMobile() ? 'mobile' : 'desktop'
    })
    params['params']['popularity'] = 'default'

    if (params['params']['utm']) {
      params['params']['flags']['utm'] = params['params']['utm']
    }
    if (params['params']['geo']) {
      const geoRadius = 50
      params['destination'] = params['params']['geo']['name']
      params['params']['geo']['lat'] = parseFloat(params['params']['geo']['lat'])
      params['params']['geo']['lon'] = parseFloat(params['params']['geo']['lon'])
      params['params']['geo']['radius'] = geoRadius
    }

    if (page === 'map') {
      if (params['params']['hotels_ids'].length) params['params']['hotels_ids'] = []
    }
    return params
  }

  mapHotelInfoRequest (source, id, callback) {
    Params.params.locale = Locale.fixLocale(Params.params.locale)
    Params.params.fallback_locale = __('auto_generated.locales_fallback')[LOCALE]
    let requestParams = {
      page: 'map',
      search_id: this.searchId,
      filters: Filters.getState(),
      params: _extend({}, this.addServiceMarker(Params.params), {hotels_ids: [parseInt(id)], host: window.location.hostname}),
      limit: 1,
      offset: 0
    }
    requestParams['params']['flags'] = {
      'auid': cookies.get('auid_ab'),
      'ab': cookies.get('cab')
    }
    if (requestParams['params']['utm']) {
      requestParams['params']['flags']['utm'] = requestParams['params']['utm']
    }

    this._makeXHR(source, requestParams, callback)
  }

  _doRequest (source, page) {
    if (this.searchFailed) return false
    let requestParams = this.requestParams(page)
    let showLoader = this.requestsInfo[page].loader

    if (source === 'initial' || (this.searchId === '' && !this.searchStarted)) {
      dispatcher.send('start_search', {request_id: this.searchId, requestParams}, 'request_processor')
      this.searchStarted = true
      this.searchFinished = false
      if(performance.mark){
        performance.mark('hotels_application-request_sent')
        performance.measure('first_chunk_sent', 'hotels_application-start', 'hotels_application-request_sent')
        let measures = performance.getEntriesByName("first_chunk_sent")
        metrics.reach_goal('FIRST_RESULT_SENT', measures[0].duration)
        performance.clearMarks()
        performance.clearMeasures()
      }
    }

    if (this.requestsInfo[requestParams.page].xhr && 'abort' in this.requestsInfo[requestParams.page].xhr) {
      // console.log(`CANCEL request ${requestParams.page}, ${source}`)
      this.requestsInfo[requestParams.page].xhr.abort()
    }

    dispatcher.send(
      'request_started',
      { request_id: this.searchId, requestParams, source, showLoader },
      'request_processor'
    )

    // console.log(`SEND request ${requestParams.page}, ${source}`)
    this.requestsInfo[requestParams.page].xhr = this._makeXHR(source, requestParams, this._searchProcessing)
    this.requestsInfo[page].loader = false
  }

  _makeXHR (source, requestParams, callback) {
    return xhr({
      body: JSON.stringify(requestParams),
      uri: '/api/wl_search/result',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      }
    }, (err, resp, body) => {
      this._dataProcessing(requestParams, err, resp, body, source, callback)
    })
  }

  _searchProcessing (requestParams, body, source, err) {
    if(source === 'initial') {
      let name = window.location.origin + '/api/wl_search/result'
      if (performance.getEntriesByName) {
        let entries = performance.getEntriesByName(name)
        entries.forEach(el => metrics.reach_goal('FIRST_RESULT_LOADED', el))
      }
    }
    this._triggerDictionaryUpdates(body, requestParams)
    this._triggerHotelsUpdate(body, requestParams)

    if (body.filters && body.filters.counters !== undefined) {
      Filters.broadcast('update', body.filters.counters)
    }

    if (this.searchStarted) {
      if (body.stop) {
        dispatcher.send('search_finished', {request_id: this.searchId}, 'request_processor')
        this.searchStarted = false
        this.searchFinished = true
      } else if (source !== 'map_preload') {
        setTimeout(() => { this.pageRequest('search') }, this.chunkRequestPeriod)
      }
    }
  }

  _dataProcessing (requestParams, err, resp, body, source, dataCallback) {
    // console.log(`DONE request ${requestParams.page}, ${source}`)
    this.requestsInfo[requestParams.page].xhr = false
    dispatcher.send('request_finished', {request_id: this.searchId, requestParams}, 'request_processor')

    if (!resp || resp.statusCode !== 200) {
      if (!resp.aborted) {
        this._onError('REQUEST', err, requestParams, body, source, dataCallback, resp && resp.statusCode)
      }
      return false
    }

    try { body = JSON.parse(body) } catch (err) {
      return this._onError('RESPONSE PARSING', err, requestParams, body, source, dataCallback, 200)
    }

    if (body.ttl) {
      dispatcher.send('expiration_ttl_changed', parseInt(body.ttl, 10) * 1000, 'request_processor')
    }

    if ((this.searchId === '' && body.search_id) || (body.search_id && body.search_id !== this.searchId)) {
      dispatcher.send('search_id_changed', {prev: this.searchId, new: body.search_id}, 'request_processor')
      this.searchId = body.search_id
    }

    dataCallback.call(this, requestParams, body, source, false)
  }

  _onError (message, err, requestParams, body, source, dataCallback, status) {
    if (this.errorAttempts < 3 && (!status || status >= 500)) {
      console.error(message, err, requestParams, this.errorAttempts)
      setTimeout(() => this.pageRequest('error_attempt', requestParams.page), this.chunkRequestPeriod)
      this.errorAttempts++
      dataCallback.call(this, requestParams, body, source, true)
    } else {
      this.searchFailed = true
      dispatcher.send(
        'search_failed',
        {message, err, source, params: requestParams.params, data: {status}, body: {body}},
        'request_processor'
      )
    }
  }

  _triggerHotelsUpdate (body, requestParams) {
    if (body.search_id) {
      dispatcher.send(`${requestParams.page}_hotels_updated`, {
        request_id: this.searchId,
        hotels: body.hotels === undefined ? [] : body.hotels,
        hotels_map: body.hotels_map === undefined ? [] : body.hotels_map,
        hotels_by_id: body.hotels_by_id === undefined ? {} : body.hotels_by_id,
        hotels_suggests: body.hotels_suggests === undefined ? [] : body.hotels_suggests,
        hotel_proposals: body.proposals === undefined ? {} : body.proposals,
        selected_hotels_ids: body.selected_hotels_ids === undefined ? [] : body.selected_hotels_ids,
        discounts: body.discounts === undefined ? {} : body.discounts,
        search_finished: body.stop,
        filteredHotels: body.filtered_hotels === undefined ? 0 : body.filtered_hotels,
        totalHotels: body.total_hotels === undefined ? 0 : body.total_hotels
      }, 'request_processor')
    }
  }

  _triggerDictionaryUpdates (body, requestParams) {
    for (let i = 0; i < this.eventKeys.length; i++) {
      let key = this.eventKeys[i]

      if (body[key]) {
        let obj = {}
        obj[key] = body[key]
        dispatcher.send(`${key}_updated`, obj, 'request_processor')
      }
    }
  }
}

var requestProcessor

dispatcher.on('params_restored', (event, {params}) => {
  requestProcessor = requestProcessor || (new RequestProcessor({searchId: params.search_id}))
  if (router.page && ['serp', 'map'].indexOf(router.page) !== -1) requestProcessor.changePage(router.page)
})

dispatcher.on('page_changed', (event, {old: oldPage, new: newPage}) => {
  let requestForMapOnHotelPage = (oldPage === 'hotel_page' && newPage === 'map') || (oldPage === 'map' && newPage === 'hotel_page')
  if (requestProcessor && ['serp', 'hotel_page', 'map'].indexOf(newPage) !== -1 && !requestForMapOnHotelPage) {
    requestProcessor.changePage(newPage)
  }
})

dispatcher.on('map_preload_pins', (event, page) => {
  requestProcessor && requestProcessor.mapRequest('map_preload')
})

dispatcher.on('pagination-show-more', (event, paginator) => {
  if (requestProcessor) {
    requestProcessor.requestsInfo.serp.limit += 10
    requestProcessor.serpRequest('pagination')
  }
})

dispatcher.on('filters_state_updated', (event, state) => {
  if (requestProcessor) {
    URLFragment.update({selected_hotels_ids: []})
    requestProcessor.pageRequest('filters')
    _forEach(requestProcessor.requestsInfo, (info, index) => { info.loader = true })
  }
})

dispatcher.on('hotels_sort_order_updated', (event, state) => {
  URLFragment.update({selected_hotels_ids: []})
  requestProcessor && requestProcessor.serpRequest('sorting')
  requestProcessor.requestsInfo['serp'].loader = true
})

dispatcher.on('search_id_changed', (event, {prev: prevState, new: newState}) => {
  if (newState && newState !== '' && prevState !== '' && window.location.search.indexOf(prevState) !== -1) {
    let newLocation = window.location.pathname + window.location.search.replace(prevState, newState)
    window.history.replaceState(window.history.state, null, newLocation)
  }
})

export default () => requestProcessor
