import _forEach from 'lodash/forEach'
import _extend from 'lodash/extend'
import dictionary from 'shared/lib/dictionary.js'
import Filters from 'shared/components/filters/filters.js'
import PropertyTypesFilter from './property_types/property_types.js'
import DistrictsFilter from './districts/districts.js'
import RoomFacilitiesFilter from './room_facilities/room_facilities.js'
import HotelFacilitiesFilter from './hotel_facilities/hotel_facilities.js'
import ProposalsOptionsFilter from './proposals_options/proposals_options.js'
import StarsFilter from './stars/stars.js'
import PriceFilter from './price/price.js'
import DistanceToFilter from './distance_to/distance_to.js'
import RatingsFilter from './ratings/ratings.js'
import URLFragment from 'shared/lib/url_fragment.js'

export default class HotelFilters extends Filters {
  constructor (node, options = {}) {
    super(node, options)
    this.keyMap = {
      'prices': 'price',
      'ratings': 'rating',
      'property_types': 'types',
      'distance_to': 'distance'
    }
    this.rangeFilters = ['prices', 'ratings', 'distance_to']
    this.filters = {
      'prices': [PriceFilter, {
        two_way: true,
        steps: [0, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 12000,
          15000, 20000, 30000, 50000, 100000, Infinity],
        barchart: true,
        range: true
      }],
      'stars': [StarsFilter, {
        preview_count: 0,
        invert_defaults: true
      }],
      'ratings': [RatingsFilter, {
        two_way: false,
        steps: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10],
        barchart: false,
        show_borders: true,
        range: true
      }],
      'property_types': [PropertyTypesFilter, {
        preview_count: 0,
        invert_defaults: true
      }],
      'distance_to': [DistanceToFilter, {
        two_way: false,
        inverted: true,
        barchart: false,
        infinity: true,
        point: {},
        radius: 0,
        min: 0,
        max: 500,
        step: 1,
        multiplier: 0.1,
        mode: 'location_center',
        range: true
      }],
      'districts': [DistrictsFilter, {
        preview_count: 7,
        invert_defaults: true
      }],
      'room_facilities': [RoomFacilitiesFilter, {
        preview_count: 7,
        invert_defaults: true
      }],
      'hotel_facilities': [HotelFacilitiesFilter, {
        preview_count: 7,
        invert_defaults: true
      }],
      'proposals_options': [ProposalsOptionsFilter, {
        preview_count: 7,
        invert_defaults: true
      }]
    }
    this.urlFragmentEnabled = true
    this._init()
  }

  getState () {
    const state = super.getState()
    return this.mergeFacilites(state)
  }

  mergeFacilites (state) {
    if ('hotel_facilities' in state || 'room_facilities' in state) {
      state['hotels_amenities'] = _extend(state['hotel_facilities'] || {}, state['room_facilities'] || {})
      if ('hotel_facilities' in state) delete state['hotel_facilities']
      if ('room_facilities' in state) delete state['room_facilities']
    }

    return state
  }

  update (data) {
    if (data['hotels_amenities']) { data = this.prepareAmenities(data) }
    super.update(data)
  }

  prepareAmenities (data) {
    data = _extend(data)

    _forEach(data['hotels_amenities'], (count, id) => {
      if (id in dictionary.hotels_amenities) {
        let key = `${dictionary.hotels_amenities[id].category}_facilities`
        if (data[key] === undefined) { data[key] = {} }
        data[key][id] = count
      }
    })
    delete data['hotels_amenities']
    return data
  }

  _initEvents (dispatcher) {
    super._initEvents(dispatcher)
    dispatcher.on('map_circle_changed', (event, data) => {
      const {radius, point} = data
      const distance = { distance_to: { point, radius, max: radius, mode: 'point' } }
      this.updateState(distance)
      this._repaintSliders()
      URLFragment.update({f: this.getShortState()})
      dispatcher.send('filters_state_updated', this.getState(), 'distance_to_filter')
    })
  }
}

window.f = Filters
