import MewtwoBase from 'shared/components/mewtwo/mewtwo.js'
import Params from 'hotels/components/params_parser/search_params.js'
import dictionary from 'shared/lib/dictionary'

export default class MewtwoHotels extends MewtwoBase {
  initConfig(config = this.state, params = {}, formTypeParams = {}) {
    var searchParams = Params.params
    let guests = searchParams['rooms'][0]
    formTypeParams['active_tab'] = 'hotel'

    if (!config.hotel_name) {
      if (searchParams.hotel_name) {
        config.hotel_name = searchParams.hotel_name
      } else {
        config.hotel_name = searchParams.destination
      }
    }

    if (searchParams.check_in) params.depart_date = searchParams.check_in
    if (searchParams.check_out) params.return_date = searchParams.check_out
    params.guests = {
      'adults': guests['adults'],
      'children': guests['children'].length,
      'children_age': guests['children'].slice(0)
    }
    super.initConfig(config, params, formTypeParams)

    if (searchParams.locations_ids) {
      window.TP_FORM_SETTINGS[config.handle]['hotel']['search_id'] = searchParams.locations_ids[0]
    }

    if (searchParams.hotels_ids) {
      window.TP_FORM_SETTINGS[config.handle]['hotel']['search_id'] = searchParams.hotels_ids
      window.TP_FORM_SETTINGS[config.handle]['hotel']['search_type'] = 'hotel'
    }
    this.location = ''
  }

  setDestination(destination, id, idKey = 'cityId', cityId) {
    if (!window.mewtwoForms) return false
    window.mewtwoForms.forms.forEach((form) => {
      let params = { name: `${destination}`, [idKey]: id }
      if (cityId) {
        params.cityId = cityId
      }
      form.forms.hotels.setAutocompleteData('place', params)
    })
  }

  _initEvents(dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('params_restored', (event, { params }) => {
      this.locationId = params.locations_ids !== undefined ? params.locations_ids[0] : false
      this.hotelId = params.hotels_ids !== undefined ? params.hotels_ids[0] : false
      let destination = this.hotelId && params.hotel_name ? params.hotel_name : params.destination
      if (this.locationId) destination = dictionary.getName('locations', this.locationId) || destination
      if (destination) {
        let key = 'cityId'
        let id = this.hotelId || this.locationId
        if (this.hotelId) key = 'hotelId'
        dispatcher.on('mewtwo_init', () => {
          if (!locationSetted) this.setDestination(destination, id, key)
        })
      }
    })

    let locationSetted = false
    dispatcher.on('locations_updated', (event, { locations }) => {
      if (!locationSetted && locations[this.locationId] && !this.hotelId) {
        locationSetted = true
        const { name, parents_names, country_name } = locations[this.locationId]
        this.setDestination(`${name}, ${parents_names ? `${parents_names}` : `${country_name}`} `, this.locationId, 'cityId')
      }
    })

    dispatcher.on('hotel_page_hotels_changed', (event, { hotel }) => {
      if (!locationSetted && this.hotelId && hotel && hotel.id === this.hotelId) {
        locationSetted = true
        this.setDestination(hotel.name, this.hotelId, 'hotelId', hotel.location_id)
      }
    })
  }
}
