moment = require('moment')

module.exports = () ->
  return {
    # destination: null, #попробовать взять из window.TPWLCONFIG
    # locations_ids: null, #попробовать взять из window.TPWLCONFIG
    check_in: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    check_out: moment(new Date()).add(2, 'days').format('YYYY-MM-DD'),
    marker: window.TPWLCONFIG["affiliate"] && (window.TPWLCONFIG["affiliate"]["marker"] + ''),
    currency: window.TPWLCONFIG["currency"],
    locale: LOCALE
    rooms: [
      "adults": 2,
      "children": []
    ]
  }
