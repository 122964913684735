import converter from 'shared/lib/converter.js'
import cookies from 'shared/lib/cookies.coffee'
import dispatcher from 'shared/lib/dispatcher'
import marker from 'shared/lib/marker.coffee'
import moment from 'moment'
import previewParamsParser from 'shared/lib/preview_params.coffee'
import router from 'shared/lib/router.js'

let instance = null

export default class Params {
  constructor () {
    if (!instance) { instance = this }
    this.isRestored = false
    this.isReadyToShow = false
    this.parsedParams = {}
    this.mainTemplateNode = document.querySelector('[role="tpwl-content"]')
    this.isPreview = previewParamsParser.is_preview()
    if (this._initParams()) {
      this._checkParamsAndSendTriggers()

      this._requestAdditionalData()
    }

    return instance
  }

  createDatesFormatted (from, to) {
    const startDate = moment.dayLongMonthName(from)
    const finishDate = moment.dayLongMonthNameNextYear(to)
    return `${startDate} - ${finishDate}`
  }

  _getParams () { throw new Error('Implement `_getParams` method') }

  _initParams () {
    this.params = this._getParams()
    if (!this.params) return false
    marker.handle_marker(this.parsedParams.marker)
    this.params = this._castParams(this.params)

    if (this.params.currency === undefined) {
      let defaultCurrency = window.TPWLCONFIG.currency
      let cookieCurrency = cookies.get('currency')
      this.params.currency = (!this.isPreview && cookieCurrency && cookieCurrency.toLowerCase()) || defaultCurrency
    }
    converter.currency = this.params.currency

    this._sendDefaultEvents()

    return true
  }

  _sendDefaultEvents () {
    dispatcher.send('currency_updated', this.params.currency, 'parser_search')
    dispatcher.send('marker_restored', this.params.marker, 'parser_search')
  }

  _castParams (params) {
    return params
  }

  _requestAdditionalData () {}

  _checkParamsAndSendTriggers () {}

  _setPage (defaultPage) {
    let page = this._getPage() || defaultPage
    if (page) router.page = page
  }

  _getPage () {
    return this.mainTemplateNode.getAttribute('data-front') === 'true' ? 'front' : 'serp'
  }
}
