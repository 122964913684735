import _extend from 'lodash/extend'
import DOMComponent from 'shared/components/base'
import Device from 'shared/lib/device.js'
import PopupScroller from 'shared/lib/popup_scroller.js'
import Template from './reviews.monk'
import moment from 'moment'
import reviewRequest from './review_request.js'

export default class HotelReviews extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'hotel_reviews',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.hotel_page-reviews-expander-spinner .spinner div': {
            'background': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-reviews-expander-mobile__text': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.hotel_page-reviews.hotel_page-reviews--expanded': {
            'background-color': window.TPWLCONFIG.color_scheme.body_bg
          },
          '.hotel_page-reviews.hotel_page-reviews--expanded .hotel_page-reviews__header': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.hotel_page-reviews-expander': {
            'color': window.TPWLCONFIG.color_scheme.link
          }
        }
      },
      render: {
        template: Template,
        replace: true,
        options: {
          filters: {
            dateString: (stamp) => moment(stamp + '000', 'x').format('DD MMMM YYYY')
          }
        }
      },
      state: {
        hotelId: false,
        expanded: false,
        textKeys: ['text_plus', 'text_minus', 'text'],
        sortedIds: ['', 2, 29, 1022, 1001],
        reviews: [],
        reviews_by_gate: {},
        stats: {'': 0},
        counts: {},
        gates: {
          '': {
            name: 'all_reviews',
            beauty_name: __('hotel_page.all_reviews')
          }
        },
        currentGate: ''
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.partSize = 10
    this.firstResponse = true
  }

  get reviewsNode () {
    if (!this._reviewsNode) this._reviewsNode = this.view.createDocument()
    return this._reviewsNode
  }

  _initDOMEvents (view) {
    view.on('click', '[role="reviews_expander"]', (event, target) => {
      this.loadReviews(true)
    })
    view.on('click', '[role="reviews_tab"]', (event, target) => {
      this.state.currentGate = parseInt(target.dataset.gate, 10) || ''
      const count = this.state.counts[this.state.currentGate]
      this.refresh()
      if (count < this.partSize && count < this.state.stats[this.state.currentGate]) this.loadReviews(true)
    })

    view.on('click', '[role="reviews_mobile_expander"]', (event, target) => {
      Device.lockScroll()
      this.state.expanded = true
      this.refresh()
      PopupScroller.toggle()
    })

    view.on('click', '[role="hotel_page-mobile-back"]', (event, target) => {
      Device.unlockScroll()
      this.state.expanded = false
      this.refresh()
      delete this.reviewsNode
      PopupScroller.closePopup()
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('params_restored', (event, params) => {
      this.state.hotelId = params.params.hotels_ids[0]
      this.loadReviews()
    })
  }

  loadReviews (nextPart = false) {
    this.reviewsNode.classList.add('hotel_page-reviews--loading')
    reviewRequest(this.state.hotelId, (data) => {
      this._processData(data, nextPart)
    }, this._getRequestParams(nextPart))
  }

  _getRequestParams (nextPart = false) {
    const offset = this.state.counts[this.state.currentGate] || 0

    return {
      gates: this.state.currentGate,
      offset: this.state.currentGate === '' ? 0 : offset, // Do not fix without understanding
      limit: nextPart ? offset + this.partSize : this.partSize
    }
  }

  _processData (data, nextPart = false) {
    const firstResponse = this.firstResponse
    if (!data) return false
    if (firstResponse) this._firstInit(data)

    if (data.reviews && data.reviews.length) {
      let reviewsByGate = this.state.reviews_by_gate
      data.reviews.forEach((review) => {
        let id = this._getReviewId(review)
        if (review.url !== '' && this.state.gates[review.gate_id].url === undefined) {
          this.state.gates[review.gate_id].url = review.url
        }
        this.state.reviews[id] = review
        if (!reviewsByGate[review.gate_id][id]) {
          this.state.counts[''] += 1
          this.state.counts[review.gate_id] += 1
        }
        reviewsByGate[review.gate_id][id] = true
        if (firstResponse || nextPart) reviewsByGate[''][id] = reviewsByGate[review.gate_id][id]
      })
    }

    this.reviewsNode.classList.remove('hotel_page-reviews--loading')
    this.refresh()
  }

  _firstInit (data) {
    this.firstResponse = false
    _extend(this.state.gates, data.gates)

    this.state.sortedIds.forEach((gateId) => {
      this.state.counts[gateId] = 0
      this.state.reviews_by_gate[gateId] = {}
      if (data.gates_stat[gateId]) {
        this.state.stats[''] += this.state.stats[gateId] = data.gates_stat[gateId].count
      }
    })
  }

  _getReviewId ({gate_id, created_at, author_name, text, text_minus, text_plus}) {
    return [gate_id, created_at, author_name, text.length, text_plus.length, text_minus.length].join(':')
  }
}
