{% import Device from 'shared/lib/device.js' %}
{% import paramsPresenter from 'hotels/components/params_parser/params_presenter' %}

<div class="hotel_page-available_rooms{{ hotel.proposal ? '' : ' hotel_page-available_rooms--no_proposals'}}">
  <div class="hotel_page-available_rooms-header">
    {% if hotel.proposal %}
      {{ __('hotel_page.available_rooms_on') }}&nbsp;
      <span class="hotel_page-available_rooms-header__link" role="available_rooms-form_trigger">
        {{ paramsPresenter.booking_period_text }},&nbsp;{{ paramsPresenter.guests }}
      </span>
    {% else %}
      {{ __('hotel_page.no_have_available_rooms') }}
    {% endif %}
  </div>
  <div class="hotel_page-available_rooms-form_container" role="available_rooms-form-container">
    <div class="hotel_page-available_rooms-form_container__form" role="available_rooms-form" id="tpwl-side-form"></div>
    <div class="hotel_page-available_rooms-form_container-tooltip">
      <div class="hotel_page-available_rooms-form_container-tooltip__button" role="available_rooms-form_trigger">
        {{ __('hotel_page.available_rooms_select_dates') }}
      </div>
      <div class="hotel_page-available_rooms-form_container-tooltip__text">
        {{ __('hotel_page.available_rooms_tooltip_text') }}
      </div>

      <div class="hotel_page-available_rooms-form_container-tooltip__logos">
        <img src="https://pics.avs.io/hl_gates/{{Device.pixelsWithRatio(130)}}/{{Device.pixelsWithRatio(40)}}/97--center.png"/>
        <img src="https://pics.avs.io/hl_gates/{{Device.pixelsWithRatio(130)}}/{{Device.pixelsWithRatio(40)}}/1--center.png"/>
      </div>
    </div>
  </div>
</div>
